import styles from "./styles/NoticeCard.module.css"
import React from "react";

function NoticeCard({ title, content, height = "200px", left_img_src, right_img_src }) {
    return (
        <div style={{
            backgroundColor: "#f5f5f5",
            paddingBottom: "15px"
        }}>
            <div
                className={styles.wrapper}
                style={{
                    // height: height,
                    color: "white",
                }}
            >
                <div>
                    <img className={styles.left_img} src={left_img_src} alt="logo" />
                </div>
                <div className={styles.left_box}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.content}>{content}</p>
                </div>
                <div className={styles.right_box}>
                    <img src={right_img_src} alt="feature" />
                </div>
            </div>
        </div>
    );
}

export default NoticeCard