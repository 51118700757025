import React, { useEffect } from "react";
import App from "./App";
import { Router } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { history } from "./_helpers/history";
import { restoreAccessToken, setAuthToken } from "./_helpers/auth";
import { queryClient } from "./_helpers/query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./styles/global.css";
import "./styles/tables.css";
import "./styles/anims.css";
// import "./qui/forms.css"
import ReactGA from "react-ga4";

// import "antd/dist/antd.variable.min.css";
import { ConfigProvider, theme } from "antd";
import { PRIMARY_ACTIVE_BG, PRIMARY_COLOR } from "./constants";

const access_token = restoreAccessToken();
if (access_token) {
    setAuthToken(access_token);
} else {
    setAuthToken();
}

const Application = () => {

    useEffect(() => {
        ReactGA.initialize("G-8P3NE3T6QD");
    }, [])


    return (
        <ConfigProvider
            theme={{
                // algorithm: theme.darkAlgorithm,
                token: {
                    // Global settings
                    colorPrimary: PRIMARY_COLOR,
                    // borderRadius: 3,
                    // colorBgContainer: "transparent",
                    // colorBgContainer: "#f7f4e8",
                    // fontFamily: "monospace",
                },
                components: {
                    Form: {
                        // Form component settings
                        itemMarginBottom: 12,
                        verticalLabelPadding: "0 0 6px",
                    },
                    Menu: {
                        itemSelectedBg: "#dde4ed",
                    },
                    Calendar: {
                        itemActiveBg: PRIMARY_ACTIVE_BG,
                    },
                },
            }}
        >
            <Router history={history}>
                <QueryClientProvider client={queryClient}>
                    <App />
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </QueryClientProvider>
            </Router>
        </ConfigProvider>
    );
};
export default Application;
