import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { secureAxios } from "../../_helpers/auth";
import { queryClient } from "../../_helpers/query";
import log from "cslog";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

function getQuery(collection) {
    if (collection === "directus_users") {
        return "users";
    } else if (collection === "roles" || collection === "directus_roles") {
        return "roles";
    } else if (collection === "directus_files") {
        return "files";
    }
    return `items/${collection}`;
}

export const useItems = (collection, preset, enabled) => {
    return useQuery(
        ["items", collection],
        () => secureAxios.get(`/items/${collection}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useSingleItem = (collection, queryString, enabled) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    } else if (collection === "directus_files") {
        query = "files";
    }
    return useQuery(
        ["items", collection, queryString],
        () =>
            secureAxios.get(`/${query}${queryString}`).then((res) => res.data),
        {
            enabled: enabled,
        }
    );
};

export const useItemsFine = (collection, fieldsString, enabled, refresh) => {
    let query = `items/${collection}`;
    if (collection === "directus_users") {
        query = "users";
    } else if (collection === "roles" || collection === "directus_roles") {
        query = "roles";
    } else if (collection === "directus_files") {
        query = "files";
    }
    return useQuery(
        ["items", collection, fieldsString],
        () =>
            secureAxios
                .get(`/${query}?${fieldsString}`)
                .then((res) => res.data),
        {
            enabled: enabled,
            staleTime: twentyFourHoursInMs,
            refetchInterval: refresh && refresh > 0 ? refresh * 1000 : false,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
        }
    );
};

export const useCreateItemMutation = () => {
    return useMutation(
        (data) => {
            return secureAxios
                .post(`/items/${data.collection}`, data.data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", variables.collection]);
                if (variables.extraInvalidate) {
                    console.log(variables.extraInvalidate, "Invaliding extra");
                    queryClient.invalidateQueries(variables.extraInvalidate);
                }
            },
        }
    );
};

export const useDeleteItemMutation = () => {
    return useMutation(
        (data) => {
            return secureAxios.delete(`/items/${data.collection}/${data.id}`);
        },
        {
            onSuccess: (data, variables) => {
                log.p("Mutation Success");
                queryClient.invalidateQueries(["items", variables.collection]);
                if (variables.extraInvalidate) {
                    console.log(variables.extraInvalidate, "Invaliding extra");
                    queryClient.invalidateQueries(variables.extraInvalidate);
                }
            },
        }
    );
};

export const useUpdateItemMutation = (onSuccess = () => { }) => {
    return useMutation(
        (data) => {
            return secureAxios.patch(
                `/${getQuery(data.collection)}/${data.id}`,
                data.data
            );
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(["items", variables.collection]);
                onSuccess();
            },
        }
    );
};

export const useUpdateItemsMutation = () => {
    return useMutation(
        (data) => {
            return secureAxios.patch(`/items/${data.collection}`, data.data);
        },
        {
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries(["items", variables.collection]);
            },
        }
    );
};
