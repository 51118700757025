import styles from "./styles/SingleTemplateItem.module.css";
import React from "react";
import { Tooltip } from "antd";
import { BASE_URL } from "../constants";
import { restoreAccessToken } from "../_helpers/auth";
import AvatarPhoto from "../media/imgs/avatar2.jpeg";

function SingleTemplateItem({
    item,
    toggleFav,
    isFav,
    applyTemplate,
    creating = false,
    height = 200,
    onView = () => { },
}) {
    return (
        <div className={styles.wrapper}>
            <img
                className={styles.img}
                src={getImageURL(item.preview?.id)}
                alt="img"
                style={{
                    height: height,
                }}
            />
            <div className={styles.overlay}>
                <Tooltip title="Use this template">
                    <button className={styles.giBtn} onClick={applyTemplate}>
                        <i
                            className={
                                creating
                                    ? "fas fa-cog fa-spin"
                                    : "fas fa-arrow-right"
                            }
                        ></i>
                    </button>
                </Tooltip>
                <Tooltip title="Preview">
                    <button className={styles.giBtn} onClick={onView}>
                        <i className="fas fa-eye"></i>
                    </button>
                </Tooltip>
                <Tooltip title="Favourite">
                    <button
                        className={styles.loveBtn}
                        style={{
                            color: isFav ? "red" : "white",
                        }}
                        onClick={toggleFav}
                    >
                        <i className="fas fa-heart"></i>
                    </button>
                </Tooltip>
                {/* <Tooltip title={item.title}>
                    <h2 className={styles.gridItemTitle}>{item.title}</h2>
                </Tooltip> */}
            </div>
        </div>
    );
}

export default SingleTemplateItem;

function getImageURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}?key=system-medium-contain&access_token=${restoreAccessToken()}`;
    } else {
        return AvatarPhoto;
    }
}
