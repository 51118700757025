import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { useEventCalender } from "../actions/events";
import log from "cslog";
import styles from "./styles/ContentCalender.module.css";
import { PreviewTemplateList } from "../qmade/creator/PreviewTemplateList";
import { getImageFileURL, getImageURL, getPublicImageURL } from "../_helpers/image";
import { Button } from "antd";
import CollectionForm from "./CollectionForm";
import CollectionPreview from "./CollectionPreview";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

function MyCollections({ me = {} }) {
    const { isSuccess, isLoading, data } = useEventCalender(true);
    const [open, setOpen] = useState(false)
    const [selected_card, setSelectedCard] = useState(null);

    const current_month = new Date().getMonth();
    const current_day_of_month = new Date().getDate();
    const months_seq = [
        ...MONTHS.slice(current_month, MONTHS.length),
        ...MONTHS.slice(0, current_month),
        MONTHS[current_month]
    ]


    // log.d(data, "Event calender data");
    // log.d(open, "Now Open is")
    log.d(months_seq, "Months Seq")
    log.d(current_month, "Current Month")
    log.d(current_day_of_month, "Current Day")

    return (
        <QurealSection header={<SectionHeader
            heading="My Collections"

        >
            <Button type="primary" >Create Collection</Button>
        </SectionHeader>}>
            {/* <PreviewTemplateList
                    tags={["gandhi"]}
                    enable={true}
                    open={true}
                    setOpen={setOpen}
            /> */}
            <div className={styles.wrapper}>
                <CollectionForm />
                {/* <CollectionPreview /> */}
            </div>
            {/* {selected_card && <PreviewTemplateList
                enable={open}
                filter={selected_card?.filter ? JSON.stringify(selected_card.filter) : null}
                open={open}
                setOpen={setOpen}
                me={me}
            />} */}
        </QurealSection>
    );
}

export default MyCollections;
