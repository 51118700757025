import styles from './styles/BrowseTemplates.module.css'
import React, { useEffect, useState } from 'react'
// import sample_img from "../../media/imgs/avatar.jpg";
import { Tooltip } from 'antd'
import { BASE_URL, CREATOR_BASE_URL } from '../constants'
import {
    useCreateItemMutation,
    useDeleteItemMutation,
    useItemsFine,
    useSingleItem
} from '../actions/app/items'
import log from 'cslog'
// import { getImageURL } from "../_helpers/image";
import { nanoid } from 'nanoid'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { CREATION_TYPES } from '../creation_constants'
import { Redirect, Route, useLocation } from 'react-router-dom'
import AvatarPhoto from '../media/imgs/avatar2.jpeg'
import { restoreAccessToken } from '../_helpers/auth'
import classNames from 'classnames'
import { useMe } from '../actions/auth'
import cloneDeep from 'lodash/cloneDeep'
import { getCreateURL, getEditURL } from '../_helpers/editor'
import SingleTemplateItem from './SingleTemplateItem'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { BlankItem } from '../qmade/creator/BrowseTemplates'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import PreviewTemplate from '../qmade/creator/PreviewTemplate'

const CREATOR_URL = CREATOR_BASE_URL

export function getImageURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}?key=system-medium-contain&access_token=${restoreAccessToken()}`
    } else {
        return AvatarPhoto
    }
}

function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

const BrowseTemplates = ({
    creation_type,
    show_as_row = false,
    height = 200,
    filter = null,
    heading = null,
    me = {}
}) => {
    let query = useQuery()
    const [type, setType] = useState(undefined)
    const [name, setName] = useState('')
    const {
        data: dataTemplates,
        isSuccess: successTemplates,
        isLoading: loadingTemplates
    } = useItemsFine(
        // "qp_template",
        'qdb_creation_template',
        // `filter[type][_eq]=${type}&fields=*,preview.*`,
        `fields=*,preview.*&sort=-date_created&filter=${JSON.stringify(filter)}`,
        type !== undefined
    )
    const { data: dataMe, isSuccess: successMe } = useMe()
    const { data: dataFav, isSuccess: successFav } = useItemsFine(
        // "qp_template_directus_users",
        'qdb_creation_template_directus_users',
        `filter[directus_users_id][_eq]=${dataMe?.data?.id}`,
        successMe
    )
    const [allFavs, setAllFavs] = useState([])
    const createMutation = useCreateItemMutation()
    const createCreationMutation = useCreateItemMutation()
    const deleteMutation = useDeleteItemMutation()

    const [view_item, setViewItem] = useState(false)
    const [open_view, setOpenView] = useState(false)

    const [applyId, setApplyId] = useState(null)
    const {
        data: dataSingle,
        isSuccess: successSingle,
        isLoading: loadingSingle
        // } = useSingleItem("qp_template", `/${applyId}`, applyId !== null);
    } = useSingleItem('qdb_creation_template', `/${applyId}`, applyId !== null)

    useEffect(() => {
        if (applyId) {
            if (successSingle && dataSingle) {
                log.d(dataSingle, 'Cloning from')
                const newData = cloneDeep(dataSingle.data)
                newData['slug'] = `${newData['slug']}_${nanoid(5)}`
                newData['organization'] = dataMe?.data?.selected_org?.id
                log.d(newData, 'New Data for Creation')
                createCreationMutation.mutate({
                    collection: 'qp_creation',
                    data: newData,
                    from_id: applyId
                })
                setApplyId(null)
            }
        }
    }, [applyId, successSingle, dataSingle])

    const applyTemplate = id => {
        log.p(`Applying template: ${id}`)
        setApplyId(id)
    }

    const markFavourite = id => {
        log.p(`marking favourite ${id}`)
        if (successMe) {
            const out = {
                // collection: "qp_template_directus_users",
                collection: 'qdb_creation_template_directus_users',
                data: {
                    // qp_template_id: id,
                    qdb_creation_template_id: id,
                    directus_users_id: dataMe?.data?.id
                }
            }
            createMutation.mutate(out)
        }
    }

    const removeFavourite = row_id => {
        log.p(`removing favourite ${row_id}`)
        if (row_id) {
            deleteMutation.mutate({
                // collection: "qp_template_directus_users",
                collection: 'qdb_creation_template_directus_users',
                id: row_id
            })
        }
    }

    useEffect(() => {
        if (successFav) {
            // const allFavs = dataFav.map((item) => item.qp_template_id);
            const allFavs = dataFav.map(item => item.qdb_creation_template_id)
            setAllFavs(allFavs)
        }
    }, [successFav, dataFav])

    useEffect(() => {
        const type = query.get('type') || creation_type
        const item = CREATION_TYPES.find(item => item.id === type)
        if (item) {
            setType(type)
            setName(item['name'])
        }
    }, [query, creation_type])

    if (successTemplates) {
        log.d(dataTemplates, 'DATA Templates')
    }

    if (createCreationMutation.isSuccess) {
        log.d(createCreationMutation, 'Create Creation Mutation')
        if (createCreationMutation?.data?.id) {
            return (
                <Route
                    path='/'
                    component={() => {
                        // window.location.href = `${CREATOR_URL}${createCreationMutation?.data?.id}/edit`;
                        window.location.href = getEditURL(createCreationMutation?.data?.id)
                        return null
                    }}
                />
            )
        }
    }

    console.log('templates data', dataTemplates)

    return (
        <div className={styles.wrapper}>
            {!filter && <p>Filter not configured</p>}
            {heading && <h2 className={styles.title}>{heading}</h2>}
            <BrowseWrapper show_as_row={show_as_row}>
                {type &&
                    successTemplates &&
                    dataTemplates.map((item, index) => {
                        const isFav = allFavs.includes(item.id)
                        return (
                            <ItemWrapper show_as_row={show_as_row}
                                item={item}
                                height={height}
                            >
                                <SingleTemplateItem
                                    item={item}
                                    isFav={isFav}
                                    creating={
                                        createCreationMutation.isLoading &&
                                        createCreationMutation?.variables?.from_id === item.id
                                    }
                                    toggleFav={() => {
                                        if (isFav) {
                                            const row = dataFav.find(
                                                favItem =>
                                                    // favItem.qp_template_id ===
                                                    favItem.qdb_creation_template_id === item.id
                                            )
                                            removeFavourite(row?.id)
                                        } else {
                                            markFavourite(item.id)
                                        }
                                    }}
                                    applyTemplate={() => applyTemplate(item.id)}
                                    height={height}
                                    onView={() => {
                                        setViewItem(item)
                                        setOpenView(true)
                                    }}
                                />
                            </ItemWrapper>
                        )
                    })}
            </BrowseWrapper>

            {open_view && (
                <PreviewTemplate
                    me={me}
                    item={view_item}
                    open={open_view}
                    setOpen={setOpenView}
                    is_template={true}
                    slug_field='id'
                />
            )}
        </div>
    )
}

export default BrowseTemplates

function ItemWrapper({ children, show_as_row = false, item, height }) {
    if (show_as_row) {
        return <SwiperSlide
            style={{
                // backgroundColor: "cyan",
                width: (item.preview?.width * height) / item.preview?.height,
                // width: show_as_row ? (item.preview?.width * height) / item.preview?.height : height,
                height: height
            }}
        >
            {children}
        </SwiperSlide>
    } else {
        return children
    }
}

function BrowseWrapper({ children, show_as_row = false }) {
    if (show_as_row) {
        return (
            <Swiper
                spaceBetween={15}
                slidesPerView='auto'
                slidesPerGroupAuto={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={swiper => console.log(swiper)}
                modules={[Navigation]}
                navigation={true}
            >
                {children}
            </Swiper>
        )
    } else {
        return (
            <div
                style={{
                    // display: 'flex',
                    // flexDirection: 'row',
                    // flexWrap: 'wrap',
                    display: "grid",
                    gridTemplateColumns: "repeat( auto-fit, minmax(250px, 1fr) )",
                    gap: '10px'
                }}
            >
                {children}
            </div>
        )
    }
}
