
import { useParams } from "react-router-dom";
import QurealSection from "../../../common/QurealSection";
import SectionHeader from "../../../components/commons/SectionHeader";
import { useItemsFine, useSingleItem } from "../../../_actions/app/items";
import { getImageURL, getPublicImageURL } from "../../../_helpers/image";
import styles from "../styles/BrandStyles.module.css";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddBrandOrganization from "./AddBrandOrganization";
import { useCurrentUser } from "../../../actions/auth";
import log from "cslog";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Tabs, Collapse, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";
import PhotoButton from "../../../qforms/standard_elements/PhotoButton";
import { useCreateItem, useUpdateItem } from "../../../_actions/mutations/items";
import { useHistory } from "react-router-dom"
import { nanoid } from "nanoid"
import snakeCase from "lodash/snakeCase";
import ManageBrandMembers from "./ManageBrandMembers";



function PersonEditPage({ params }) {
    const { id } = useParams();
    const history = useHistory();
    const { isLoading: loadingMe, isSuccess: successMe, data: dataMe } = useCurrentUser();
    const [show, setShow] = useState(false);
    const { data, isSuccess, isLoading } = useSingleItem(
        "qdb_person",
        // "filter[owner][_eq]=$CURRENT_USER&fields=*,users.*",
        `/${id}?fields=*`,
        true
    );
    const [form] = Form.useForm();
    const mutationCreate = useCreateItem("qdb_person", () => {
        history.push("/settings/brands")
    }
    );
    const mutationUpdate = useUpdateItem(
        "qdb_person",
        id,
        () => { history.push("/settings/brands") }
    );
    const [tab, setTab] = useState("info")

    const is_update = id !== undefined;

    log.d(id, "Person id")
    log.d(data, "Person data")

    const addPerson = (values) => {
        log.d(values, "Person values");
        const new_data = {
            ...values,
            // id: `${values.first_name}_${values.last_name}_${nanoid(5)}`,
            user: dataMe?.id,
            full_name: `${values.first_name} ${values.last_name}`,
            profile_photo_casual: values.profile_photo_casual || values.profile_photo_formal,
        }
        log.d(new_data, "New data");
        // return;
        if (!!id) {
            log.p("Updating person");
            mutationUpdate.mutate(new_data);
        } else {
            log.p("Creating person");
            mutationCreate.mutate(new_data);
        }
    };


    const is_owner = data?.user === dataMe?.id;


    return <QurealSection header={<SectionHeader heading={is_update ? "Update Person" : "Add New Person"} >
        <Button key="cancel" onClick={() => {
            // history.goBack();
            history.push("/settings/brands");
        }}>
            Cancel
        </Button>
        <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={
                mutationCreate.isLoading || mutationUpdate.isLoading
            }
        >
            {is_update ? "Update" : "Create"}
        </Button>
    </SectionHeader>} >
        <div style={{ padding: "15px", maxWidth: "700px" }}>
            {/* <p>params: {id}</p> */}
            {/* {isSuccess && <p>{JSON.stringify(data)}</p>} */}
            {isLoading && <p>Loading....</p>}
            {(isSuccess && data && data.user !== dataMe?.id) && <p>You are not authorized to edit this person details.</p>}
            {/* {(is_update ? isSuccess && data && data.user === dataMe?.id : true) && <Tabs
                onChange={(key) => {
                    console.log(key);
                    setTab(key);
                }}
                activeKey={tab}
                // type="card"
                items={[
                    {
                        label: "Person Information",
                        key: "info",
                        // children: "Brand info"
                    },
                    {
                        label: "Manage Access",
                        key: "members",
                        // children: "Brand Members"
                    }
                ]}
            />} */}
            {(isSuccess && data && is_owner && tab === "members") && <ManageBrandMembers id={id}
                members={data.users}
            />}

            {(is_update ? isSuccess && data && is_owner && tab === "info" : true) && <Form
                layout="vertical"
                form={form}
                onFinish={addPerson}
                initialValues={{
                    // ...initialValues,
                    ...data,
                    // ...(logo_hori && { logo_horizontal: logo_hori }),
                }}
            >
                <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Form.Item label="First Name" name="first_name" required>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Last Name" name="last_name" >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Form.Item label="Full Name" name="full_name">
                    <Input />
                </Form.Item> */}

                <Form.Item label="Gender" name="gender">
                    <Radio.Group>
                        <Radio value="Male">Male</Radio>
                        <Radio value="Female">Female</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Person Bio (It will help AI learn about the person)"
                    name="bio"
                >
                    <TextArea rows={4} maxLength={400} />
                </Form.Item>
                <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Form.Item label="Profile Photo Formal" name="profile_photo_formal">
                            <PhotoButton
                                field={{
                                    collection: "qqq",
                                    field: "profile_photo_formal",
                                }}
                                width="200px"
                                height="200px"
                                objectFit="contain"
                                show_placeholder={true}
                                enable_remove_bg={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12} >
                        <Form.Item label="Profile Photo Casual" name="profile_photo_casual">
                            <PhotoButton
                                field={{
                                    collection: "qqq",
                                    field: "profile_photo_casual",
                                }}
                                width="200px"
                                height="200px"
                                objectFit="contain"
                                show_placeholder={true}
                            />
                        </Form.Item>
                        <Checkbox onChange={(e) => {
                            if (e.target.checked) {
                                form.setFieldsValue({
                                    profile_photo_casual: form.getFieldValue("profile_photo_formal"),
                                    photo_photo_casual_old: form.getFieldValue("profile_photo_casual"),
                                })
                            } else {
                                if (form.getFieldValue("profile_photo_casual") === form.getFieldValue("profile_photo_formal")) {
                                    form.setFieldsValue({
                                        photo2: form.getFieldValue("profile_photo_casual_old") || null
                                    })
                                }
                            }
                            log.d(form.getFieldsValue(), "form values now")
                        }}>Use same as formal photo</Checkbox>
                    </Col>

                </Row>

                <Row gutter={[10, 10]}>
                    {/* <Col span={8}> */}
                    <Col xs={24} sm={12}>
                        <Form.Item label="Mobile Number" name="mobile">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Email" name="email">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[10, 10]}>
                    {/* <Col span={8}> */}
                    <Col xs={24} sm={12}>
                        <Form.Item label="Date of Birth" name="date_of_birth">
                            <Input type="date" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Website" name="website">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[10, 10]}>
                    {/* <Col span={8}> */}
                    <Col xs={24} sm={12}>
                        <Form.Item label="Profession" name="profession">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Website" name="website">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Form.Item label="Address" name="address">
                    <TextArea rows={3} />
                </Form.Item> */}

                <Collapse
                    // defaultActiveKey={["1"]}
                    defaultActiveKey={[]}
                    // ghost
                    items={[
                        {
                            key: "1",
                            label: "Social Media Handles",
                            children: <div>
                                <Row gutter={[10, 10]}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Linkedin Handle" name="linkedin_handle"
                                            help="Mention only handle, not full url"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Instagram Handle" name="instagram_handle"
                                            help="Mention only handle, not full url"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[10, 10]}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Facebook Handle" name="facebook_handle"
                                            help="Mention only handle, not full url"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="YouTube Handle" name="youtube_handle"
                                            help="Mention only handle, not full url"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[10, 10]}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item label="Pinterest Handle" name="pinterest_handle"
                                            help="Mention only handle, not full url"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>,
                        },
                    ]}
                    style={{
                        // border: "1px solid red",
                    }}
                />

                <Row gutter={[10, 10]} style={{ paddingTop: "10px" }} >
                    <Col span={12}>
                        <Form.Item label="Signature" name="signature">
                            <PhotoButton
                                field={{
                                    collection: "qqq",
                                    field: "signature",
                                }}
                                width="300px"
                                height="150px"
                                objectFit="contain"
                                show_placeholder={true}
                                enable_remove_bg={true}
                                background="white"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    marginTop: "15px"
                }}>
                    <Button key="cancel" onClick={() => {
                        history.push("/settings/brands");
                    }}>
                        Cancel
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={
                            mutationCreate.isLoading || mutationUpdate.isLoading
                        }
                    >
                        {is_update ? "Update" : "Create"}
                    </Button>
                </div>
            </Form>}
        </div>
    </QurealSection>
}

export default PersonEditPage;