import { i } from "cslog";
import { startCase } from "lodash";
import React, { useState } from "react";
import {
    useCreateItemMutation,
    useDeleteItemMutation,
    useUpdateItemMutation,
} from "../../../actions/app/items";
import Dropdown from "../../../qforms/elements/Dropdown";
import RelationField from "../../../qforms/elements/RelationField";
import log from "cslog";
import { Button, Form, Input, Select, Space, Tabs } from "antd";
import SubscriptionDetails from "./SubscriptionDetails";
import CollectedData from "./CollectedData";
import QFieldStandard from "../../../qforms/QFieldStandard";
import RelationFieldStanard from "../../../qforms/standard_elements/RelationFieldStandard";

const { Option } = Select;

function CreationSettings({ data, plans }) {
    return (
        <div style={{ paddingTop: "10px" }}>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        key: "overview",
                        label: "Overview",
                        children: (
                            <div>
                                <SubscriptionDetails plans={plans} />
                            </div>
                        ),
                    },
                    {
                        key: "analytics",
                        label: "Analytics",
                        children: "Content of Tab Pane 2",
                    },
                    {
                        key: "collaborators",
                        label: "Collaborators",
                        children: <CollaboratorsSetting data={data} />,
                    },
                    {
                        key: "settings",
                        label: "Settings",
                        children: (
                            <div>
                                <RenameCreation data={data} />
                                {/* <ChnageSlug data={data} /> */}
                                <TransferOwnership data={data} />
                            </div>
                        ),
                    },

                    {
                        key: "data",
                        label: "Collected Data",
                        children: (
                            <div>
                                <CollectedData creation={data} />
                            </div>
                        ),
                    },
                ]}
                onChange={() => { }}
            />
        </div>
    );
}

export default CreationSettings;

function TransferOwnership({ data }) {
    const [new_owner, setNewOwner] = useState(null);
    const updateMutation = useUpdateItemMutation();

    const proceed = (e) => {
        e.preventDefault();
        if (new_owner) {
            log.d(new_owner, "New Owner");
            updateMutation.mutate({
                collection: "qp_creation",
                id: data?.id,
                data: {
                    user_created: new_owner,
                    // organization: new_owner.organization,
                    organization: null
                },
            });
        }
    };

    return <Form layout="vertical">
        <Form.Item label="New Owner" name="name">
            <Space.Compact style={{
                maxWidth: "500px",
                display: "grid",
                gridTemplateColumns: "1fr auto"
            }}>
                <RelationFieldStanard
                    field={{
                        type: "relationM2O",
                        foreign_key_table: "directus_users",
                        foreign_key_column: "id",
                        template: "{{first_name}} {{last_name}}",
                    }}
                    many={false}
                    value={new_owner}
                    onChange={(newVal) => {
                        setNewOwner(newVal);
                    }}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    // loading={updateMutation.isLoading}
                    onClick={proceed}
                >
                    Transfer
                </Button>
            </Space.Compact>
        </Form.Item>
    </Form>

}

function RenameCreation({ data }) {
    const [name, setName] = useState(data?.title);
    const updateMutation = useUpdateItemMutation();

    const rename = (e) => {
        e.preventDefault();
        if (name !== "") {
            updateMutation.mutate({
                collection: "qp_creation",
                id: data?.id,
                data: {
                    title: name,
                },
            });
        }
    };

    log.d(data, "Data in RenameCreation");
    return (
        <Form layout="vertical">
            <Form.Item label="New Name" name="name">
                <Space.Compact style={{
                    maxWidth: "500px",
                    display: "grid",
                    gridTemplateColumns: "1fr auto"

                }}>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={updateMutation.isLoading}
                        onClick={rename}
                    >
                        Submit
                    </Button>
                </Space.Compact>
            </Form.Item>
        </Form>
    );
}

function ChnageSlug({ data }) {
    const [slug, setSlug] = useState(data?.slug);
    const updateMutation = useUpdateItemMutation();

    const rename = (e) => {
        e.preventDefault();
        if (slug !== "") {
            updateMutation.mutate({
                collection: "qp_creation",
                id: data?.id,
                data: {
                    slug: slug,
                },
            });
        }
    };

    return <Form layout="vertical">
        <Form.Item label="New Name" name="name">
            <Space.Compact style={{ width: "60%" }}>
                <Input
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                />
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={updateMutation.isLoading}
                    onClick={rename}
                >
                    Submit
                </Button>
            </Space.Compact>
        </Form.Item>
    </Form>
}

function CollaboratorsSetting({ data }) {
    const [new_colab, setNewColab] = useState({
        directus_users_id: null,
        qp_creation_id: data.id,
        access: "full",
    });
    const createMutation = useCreateItemMutation();
    const deleteMutation = useDeleteItemMutation();

    log.d(new_colab, "New colab now");

    const addColab = () => {
        if (new_colab.directus_users_id) {
            //now add
            createMutation.mutate({
                collection: "qp_creation_directus_users",
                data: new_colab,
                extraInvalidate: ["items", "qp_creation"],
            });
        }
    };

    const deleteColab = (row_id) => {
        deleteMutation.mutate({
            collection: "qp_creation_directus_users",
            id: row_id,
            extraInvalidate: ["items", "qp_creation"],
        });
    };


    return (
        <div>
            <Form layout="vertical" onFinish={(values) => {
                addColab();
            }}>
                <div style={{
                    maxWidth: "500px",
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr 1fr",
                    gap: "5px"

                }}>
                    <Form.Item label="Add New Collaborator" name="user">
                        <RelationFieldStanard
                            field={{
                                type: "relationM2O",
                                foreign_key_table: "directus_users",
                                foreign_key_column: "id",
                                template: "{{first_name}} {{last_name}}",
                            }}
                            many={false}
                            value={new_colab.directus_users_id}
                            onChange={(newVal) => {
                                setNewColab({
                                    ...new_colab,
                                    directus_users_id: newVal,
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Access" name="name">
                        <Select value={new_colab.access}
                            onChange={(newVal) =>
                                setNewColab({
                                    ...new_colab,
                                    access: newVal,
                                })
                            }>
                            {[
                                { text: "Full", value: "full" },
                                { text: "Data Only", value: "data_only" },
                                { text: "Design Only", value: "design_only" }
                            ].map((item) => {
                                return (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item label=" ">

                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={createMutation.isLoading}
                        >
                            Add
                        </Button>
                    </Form.Item>
                </div>
            </Form>

            <form
                className="qui-form"
                style={{ maxWidth: "500px", marginTop: "10px" }}
            >
                <div className="qlist">
                    {data.collaborators?.length > 0 ? (
                        data.collaborators.map((colab) => (
                            <div className="qitem">
                                <span>
                                    {colab.directus_users_id.first_name}{" "}
                                    {colab.directus_users_id.last_name}
                                </span>
                                <span>{startCase(colab.access)}</span>
                                <div className="btn_group">
                                    {/* <button className="btn">Edit</button> */}
                                    {/* <button
                                        className="btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteColab(colab.id);
                                        }}
                                    >
                                        Remove
                                    </button> */}
                                    <Button
                                        danger
                                        onClick={(e) => {
                                            e.preventDefault();
                                            deleteColab(colab.id);
                                        }}
                                        loading={deleteMutation.isLoading}
                                    >Remove</Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No collaborator added</p>
                    )}
                </div>
            </form>
        </div>
    );
}
