import styles from "./styles/TemplatesGrid.module.css";
import React, { useState } from "react";
import { useItemsFine } from "../actions/app/items";
import SingleTemplateItem from "./SingleTemplateItem";
import PreviewTemplate from "../qmade/creator/PreviewTemplate";
import TemplateEditDialog from "./TemplateEditDialog";
import startCase from "lodash/startCase";

const FIELDS = "id,type,title,status,preview.id,preview.filename_disk,tags.id,tags.qdb_tag_id.*,inputs,used_schemas.qdb_creation_schema_id.id,used_schemas.qdb_creation_schema_id.name,used_schemas.qdb_creation_schema_id.user_prompt_question"


function TemplatesGrid({ me, isMobile, status }) {
    const { isSuccess, isLoading, data } = useItemsFine(
        "qdb_creation_template",
        `fields=${FIELDS}&filter[status][_eq]=${status}&sort=-date_created`,
        true);

    const [selected_template, setSelectedTemplate] = React.useState(null);
    const [edit_template, setEditTemplate] = useState(null);

    return <div>
        <h3>{startCase(status)} Templates</h3>
        {isSuccess && <p style={{ color: "#0000005a" }}>Total count {data?.length}</p>}

        {isSuccess && data?.length > 0 && <div className={styles.grid}>
            {data.map(item => {
                return <SingleTemplateItem data={item}
                    previewTemplate={() => {
                        setSelectedTemplate(item);
                    }}
                    editTemplate={() => {
                        setEditTemplate(item);
                    }}
                />
            })}
        </div>}

        {selected_template && <PreviewTemplate
            open={true}
            setOpen={(val) => {
                if (!val) {
                    setSelectedTemplate(null);
                }
            }}
            is_template={true}
            slug_field="id"
            isMobile={isMobile}
            item={selected_template}
            enable_nav={false}
            me={me}
        />}

        {edit_template && <TemplateEditDialog
            open={true}
            setOpen={(val) => {
                if (!val) {
                    setEditTemplate(null);
                }
            }}
            item={edit_template}
            isMobile={isMobile}
            me={me}
        />}
    </div>
}

export default TemplatesGrid;