import React from "react";
import { LOGO_ONLY, TOPBAR_HEIGHT, TOPHEADER_HEIGHT } from "../constants";
// import LogoLight from "../media/imgs/Q_logo_b.png";
// import LogoDark from "../media/imgs/Q_logo_w.png";

export default function Logo({ theme = "dark" }) {
    return (
        <div
            style={{
                width: "auto",
                height: TOPBAR_HEIGHT,
                maxHeight: TOPBAR_HEIGHT,
                // background: "#f0f2f5",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "center",
                // marginBottom: "15px",
                borderBottom: "1px solid var(--border-color)",
            }}
        >
            <img
                alt="Logo"
                // src={theme === "dark" ? LogoDark : LogoLight}
                src={LOGO_ONLY}
                style={{
                    width: "auto",
                    height: "60%",
                }}
            />
        </div>
    );
}
