import React from "react";

// import LOGO from "./media/imgs/Qureal-name-trans.png";
import LOGO from "./media/imgs/1_logo_horizontal.png";
// import LOGO_SQUARE from "./media/imgs/Qureal_Logo_Only.png";
import LCCIA_LOGO from "./media/imgs/lccia_logo.jpeg";
import LOGO_US_PHARMA from "./media/imgs/logo_us_pharma.png";
import LOGO_SQUARE from "./media/imgs/0_logo_square.png";

export const EXTERNAL_URL = "http://localhost:5000/";

export const PROJECT_ID = 1;

export const TOPBAR_HEIGHT = 50;
export const MAINBAR_WIDTH = 50;
export const SIDEBAR_WIDTH = 250;
export const TOPHEADER_HEIGHT = 65;
export const TOPHEADER_HEIGHT_MOBILE = 45;

let CREATOR_BASE_URL = "http://editor.qureal.com/";
export const CREATOR_BASE_URL_DEV = "http://localhost:3000/";

export const VIEWER_BASE_URL = "http://show.qureal.com";

export const PREVIEW_CREATION_URL = "https://show.qureal.com/s/";
export const PREVIEW_TEMPLATE_URL = "https://show.qureal.com/ts/";
// export const PREVIEW_DOCUMENT_CREATION_URL = "http://localhost:3201/d/";
export const PREVIEW_DOCUMENT_CREATION_URL = "https://show.qureal.com/d/";
export const PREVIEW_DOCUMENT_TEMPLATE_URL = "https://show.qureal.com/td/";


//=====================================================================
const PORTAL = process.env.REACT_APP_INS || "main";
// const PORTAL = "test";
// Main================================================================
let ORG_NAME = "Qureal";
let BASE_URL = "https://api.qureal.com/";

// let BASE_URL = "https://api.qureal.com/";
// let PRIMARY_COLOR = "#063970";
// let PRIMARY_COLOR = "#3848ab";
// let PRIMARY_COLOR = "#04998E";
let PRIMARY_COLOR = "#00998e";
let PRIMARY_ACTIVE_BG = "#00998f13";
// let PRIMARY_COLOR = "#04947A";
let LOGO_ONLY = LOGO;
let LOGIN_BG =
    "linear-gradient(135deg, rgba(31,75,161,1) 0%, rgba(147,112,209,1) 39%, rgba(0,212,255,1) 100%)";
let USER_DEFAULT_ROLE = "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f";
// let REGISTER_COMP = React.lazy(() => import("./auth/Register"));
let REGISTER_COMP = React.lazy(() => import("./auth/RegisterNew"));

let SELF_URL = "http://app.qureal.com";
let ALLOWED_TYPES = "all";
//=====================================================================
// LCCIA Member Portal=================================================
if (PORTAL === "lccia") {
    BASE_URL = "http://156.67.217.219:3101/";
    // BASE_URL = "http://156.67.217.219:9002/";
    PRIMARY_COLOR = "#023E8A";
    LOGO_ONLY = LCCIA_LOGO;
    LOGIN_BG = `url('${process.env.PUBLIC_URL}/back.jpg')`;
    REGISTER_COMP = React.lazy(() => import("./auth/Register_LCCIA"));
} else if (PORTAL === "c_us_pharma") {
    ORG_NAME = "US Pharma";
    BASE_URL = "http://156.67.217.219:3102/";
    CREATOR_BASE_URL = "http://editor.uspharma.in/";
    // BASE_URL = "http://156.67.217.219:9002/";
    PRIMARY_COLOR = "teal";
    LOGO_ONLY = LOGO_US_PHARMA;
    // LOGIN_BG = `url('${process.env.PUBLIC_URL}/back.jpg')`;
    REGISTER_COMP = React.lazy(() => import("./auth/Register"));
    ALLOWED_TYPES = ["packaging_box"];
}
//=====================================================================
else if (PORTAL === "base") {
    BASE_URL = "http://156.67.217.219:2100/";
} else if (PORTAL === "test") {
    BASE_URL = "https://testapi.qureal.com/";
    PRIMARY_COLOR = "#264166";
    USER_DEFAULT_ROLE = "287841b1-2bf2-43b7-84fb-8c2d57bc9a34";
}
//=====================================================================

// Narkhede Sir
// export const BASE_URL = "http://156.67.217.219:9103/";
//=====================================================================

export {
    ORG_NAME,
    BASE_URL,
    PRIMARY_COLOR,
    LOGO_ONLY,
    LOGIN_BG,
    USER_DEFAULT_ROLE,
    REGISTER_COMP,
    SELF_URL,
    CREATOR_BASE_URL,
    ALLOWED_TYPES,
    PRIMARY_ACTIVE_BG,
    LOGO_SQUARE
};
