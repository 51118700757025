import { Button, Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import RelationFieldStanard from "../qforms/standard_elements/RelationFieldStandard";
import log from "cslog";
import { useCreateItemMutation, useItemsFine, useUpdateItemMutation } from "../actions/app/items";
import { useAutoTagTemplate } from "../_actions/bot";


function TemplateEditDialog({ open, setOpen, item, isMobile, me }) {
    const { isSuccess: successAllTags, isLoading: loadingAllTags, data: dataAllTags } = useItemsFine("qdb_tag", "fields=id,name&limit=-1")
    const [tags, setTags] = useState([]);
    const [render_key, setRenderKey] = useState(0);
    const mutationUpdate = useUpdateItemMutation(() => {
        //success
        log.p("Template updated successfully");
        setOpen(false);
    }, () => { }, () => { })

    const { isSuccess: successSuggestions, isLoading: loadingSuggestions, data: dataSuggestions } = useAutoTagTemplate(item, !!item.id);
    const [unique_suggestions, setUniqueSuggestions] = useState([])

    useEffect(() => {
        if (successSuggestions && successAllTags) {
            log.d(dataSuggestions, "Data suggestions from AI")
            log.d(dataAllTags, "All Tags data")

            const new_tags = []
            dataSuggestions.forEach(stag => {
                if (dataAllTags.some(tt => tt.name === stag)) {
                    const tag_full = dataAllTags.filter(t => t.name === stag)?.[0];
                    log.d(stag, `Tag ${tag_full?.id} exists`)
                    setTags(old_tags => ([...old_tags, tag_full?.id]))
                    setRenderKey(render_key + 1)
                } else {
                    new_tags.push(stag)
                }
            })

            log.d(new_tags, "New tagsss")
            setUniqueSuggestions(new_tags)
        }
    }, [successSuggestions, successAllTags])

    // useEffect(()=> {}, [])

    useEffect(() => {
        if (item) {
            setTags(item.tags.map(tag => tag.qdb_tag_id.id));
            setRenderKey(render_key + 1);
        }
    }, [item]);

    log.d(item, "Template to edit")
    log.d(tags, "Tags noww")


    const save = () => {
        const data = [];

        tags.forEach((tag) => {
            data.push({
                qdb_tag_id: { id: tag }
            })
        });

        unique_suggestions.forEach(nt => {
            data.push({
                qdb_tag_id: { name: nt }
            })
        })
        const out = {
            "tags": data
        }
        log.d(out, "Out to update")
        mutationUpdate.mutate({
            collection: "qdb_creation_template",
            id: item.id,
            data: out
        })
    }


    return <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={[
            <Button type="primary" onClick={save} loading={mutationUpdate.isLoading}>Save</Button>,
            <Button onClick={() => setOpen(false)}>Cancel</Button>
        ]}
    >
        <h3>{item.title}</h3>
        {/* <p>{JSON.stringify(tags)}</p> */}


        {loadingSuggestions && <p>Generating suggestions...</p>}
        {unique_suggestions?.length > 0 && <div>
            <label>Suggestions</label>
            <Select
                mode="multiple"
                allowClear
                onChange={(val) => setUniqueSuggestions(val)}
                defaultValue={unique_suggestions}
                showSearch={true}
                // filterOption={filterOption}
                options={unique_suggestions.map(item => ({ label: item, key: item, value: item }))}
            // style={style}
            />
        </div>}

        <label>Entries</label>
        <RelationFieldStanard
            key={render_key}
            field={{
                foreign_key_table: "qdb_tag",
                foreign_key_column: "id",
                junction_field: "qdb_tag_id",
                many_table_pk: "id",
                template: "{{qdb_tag_id.name}}",
                type: "relationM2M",
            }}
            fieldString="&limit=-1"
            value={tags}
            onChange={(val) => {
                setTags(val);
            }}
            many={true}
            style={{
                width: "100%"
            }}
            additional_options={[]}
        />

    </Modal >
}

export default TemplateEditDialog;