import { Segmented } from "antd";
import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import BrandPersonal from "./brands/person/BrandPersonal";
import BrandOrganization from "./brands/org/BrandOrganization";

function ManageBrands() {
    const [type, setType] = useState("organization");

    return (
        <QurealSection header={<SectionHeader heading="Manage Brands" />}>
            <div style={{ padding: "15px" }}>
                <Segmented
                    options={[
                        {
                            label: "Organization",
                            value: "organization",
                            icon: null,
                        },
                        { label: "Person", value: "personal", icon: null },
                    ]}
                    value={type}
                    onChange={(value) => setType(value)}
                />
                {type === "personal" && <BrandPersonal />}
                {type === "organization" && <BrandOrganization />}
            </div>
        </QurealSection>
    );
}

export default ManageBrands;

// Some creations will be only organizations
// Some will be only personal
// Some will be both like visiting card which contains both info ie company and perosonal.
