import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    useSingleItem,
    useUpdateItemMutation,
} from "../../../actions/app/items";
import log from "cslog";
import styles from "../styles/CreationSingle.module.css";
import sample_img from "../../../media/imgs/website_skelton.jpg";
import CreationSettings from "./CreationSettings";
import { getTime } from "../../../_helpers/app/dt";
import { startCase } from "lodash";
import { getEditURL, getViewerURL } from "../../../_helpers/editor";
import { Button, Modal } from "antd";
import QModal from "../../../qcomponents/QModal";
import { history } from "../../../_helpers/history";
import QurealSection from "../../../common/QurealSection";
import {
    DeleteOutlined,
    CopyOutlined,
    EyeOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { getPublicImageURL } from "../../../_helpers/image";
import { useCurrentUser } from "../../../actions/auth";
import BlankBox from "../../../common/BlankBox";

function CreationSingle({ params }) {
    const { id } = useParams();
    const { data: dataMe, isSuccess: successMe } = useCurrentUser();
    const { status: statusC, data: dataC } = useSingleItem(
        "qp_creation",
        `/${id}?fields=id,type,title,slug,preview,user_created,date_updated,user_updated.*,published_creation,collaborators.id,collaborators.access,collaborators.directus_users_id.*,subscriptions.qi_subscriptions_id.*,subscriptions.qi_subscriptions_id.plan.*`,
        id !== undefined
    );
    const [open, setOpen] = useState("");
    const deleteMutation = useUpdateItemMutation(() => {
        setOpen("");
        history.goBack();
    });
    const [plans, setPlans] = useState([]);

    const archiveItem = () => {
        deleteMutation.mutate({
            collection: "qp_creation",
            id: dataC?.id,
            data: {
                archived: true,
            },
        });
    };

    useEffect(() => {
        if (statusC === "success") {
            const subs = dataC?.subscriptions
                ?.map((item) => item?.qi_subscriptions_id)
                .filter((item) => {
                    const dt = new Date();
                    if (
                        dt > new Date(item.start_date) &&
                        dt < new Date(item.end_date)
                    ) {
                        return true;
                    } else if (item.plan?.duration === -1) {
                        return true;
                    }
                    return false;
                });
            setPlans(subs);
        }
    }, [statusC, dataC]);

    log.d(dataC, "Data Single Creation");
    log.d(plans, "Plans");
    log.d(dataMe, "Data Me");

    if (successMe && statusC === "success" && dataC?.user_created !== dataMe?.id) {
        return <BlankBox text="Permission denied" />
    }

    return (
        <QurealSection>
            {statusC === "success" && (
                <div className={styles.main}>
                    <div className={styles.two_col}>
                        <img
                            className={styles.preview}
                            // src={sample_img}
                            src={
                                dataC.preview
                                    ? getPublicImageURL(dataC.preview)
                                    : sample_img
                            }
                            alt="preview"
                        />
                        <div className={styles.info_col}>
                            <div>
                                <div className={styles.title_wrapper}>
                                    <h2>{dataC.title}</h2>
                                    <span>{startCase(dataC.type)}</span>
                                </div>
                                <p className={styles.last_edited}>
                                    Last edited{" "}
                                    <i>{getTime(dataC.date_updated)}</i> by{" "}
                                    <i>
                                        {dataC.user_updated?.first_name}{" "}
                                        {dataC.user_updated?.last_name}
                                    </i>
                                </p>
                                <p>Free Plan</p>
                            </div>

                            <div className={styles.btn_group}>
                                {/* <Button type="primary">Upgrade to Core</Button> */}
                                <a
                                    href={getEditURL(dataC.id)}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <button className="qbtn">Edit</button> */}
                                    <Button type="primary">
                                        <EditOutlined />
                                        Edit
                                    </Button>
                                </a>
                                <a
                                    href={getViewerURL(
                                        dataC.slug,
                                        dataC.type === "website"
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {/* <button className="qbtn">View</button> */}
                                    <Button type="primary">
                                        <EyeOutlined />
                                        View
                                    </Button>
                                </a>
                                {/* <button className="qbtn">Duplicate</button> */}
                                <Button>
                                    <CopyOutlined />
                                    Duplicate
                                </Button>
                                {/* <button
                                    className="qbtn qerror"
                                    onClick={() => setOpen("delete")}
                                >
                                    Delete
                                </button> */}
                                <Button
                                    danger
                                    onClick={() => setOpen("delete")}
                                    icon={<DeleteOutlined />}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>

                    <CreationSettings data={dataC} plans={plans} />
                </div>
            )}
            <QModal
                title="Delete Creation"
                open={open === "delete"}
                setClose={() => setOpen("")}
                actions={[
                    <button className="qbtn qblack" onClick={() => setOpen("")}>
                        Cancel
                    </button>,
                    <button
                        className="qbtn qred"
                        onClick={() => {
                            archiveItem();
                            // setOpen("");
                        }}
                    >
                        Delete
                    </button>,
                ]}
            >
                {statusC === "success" && (
                    <p>Do you really want to delete {dataC.title}</p>
                )}
            </QModal>
        </QurealSection>
    );
}

export default CreationSingle;
