import React, { useState } from "react";
// import "../styles/main.css";
// import "../styles/forms.css";
// import "../styles/form_thems.css";
import classNames from "classnames";
import log from "cslog";
import { useCreateItem, useUpdateItem } from "./junction";
import QFieldStandard from "./QFieldStandard";
import { Alert, Button, Form, Space } from "antd";
import {
    useCreateItemMutation,
    useUpdateItemMutation,
} from "../actions/app/items";

const encodeRecord = (template, data) => {
    log.d(template, "Tempalte now");
    log.d(data, "Data now");
    if (typeof template === "object") {
        const out = {};
        Object.entries(template).forEach((row) => {
            out[row[0]] = encodeRecord(row[1], data);
        });
        log.d(out, "Out now");
        return out;
    } else {
        log.d(data[template], "Out noww");
        return data[template];
    }
};

const ROLES_MAPPING = {
    "b35e905a-97f5-4fd4-87cc-631ecd33354b": 11, //admin
    "a4654aae-b05f-4a21-be49-af1a6fd0d4e9": 10, //manager
    "a0def5fa-b897-4fa9-b847-fd60e34acf44": 22, //qadmin
    "53ea9df9-84e7-4dc2-ae0a-db458e46ef0f": 21, //users
    "52bb1969-8b18-4e10-8798-c614f476c306": 20, //bot
};

function refineData(f, data) {
    let out = {};
    if (f.type.startsWith("relation")) {
        if (f.foreign_key_table === "roles") {
            const val = data[f.id];
            if (Array.isArray(val)) {
                out[f.name] = val.map((r) => ROLES_MAPPING[r]);
            } else {
                out[f.name] = ROLES_MAPPING[val];
            }
        } else {
            out[f.name] = data[f.name];
        }
        //proper m2m format
        if (f.type === "relationM2M" && Array.isArray(out[f.name])) {
            out[f.name] = out[f.name].map((item) => ({
                [f.junction_field]: item,
            }));
        }
    } else if (f.type === "json") {
        f.fields.forEach((ff) => {
            out = {
                ...out,
                ...refineData(ff, data),
            };
        });
    } else {
        out[f.name] = data[f.name];
    }
    return out;
}

function QFormStandard({
    form,
    fields,
    record_id,
    init_data, //for comparing to get only updated fields
    data,
    setData,
    wrapper_style = {},
    configs = {},
    onSuccess = () => { },
    onError = () => { },
    onDone = () => { },
    org,
}) {
    const [formRef] = Form.useForm();
    const [msg, setMsg] = useState({
        type: null,
    });
    // const createMutation = useCreateItem(
    const createMutation = useCreateItemMutation(
        () => {
            resetForm();
            setMsg({
                type: "success",
                msg: configs.successMessage || "Created Successfully",
            });
            onSuccess();
        },
        () => {
            setMsg({ type: "error", msg: "Error Creating" });
            onError();
        },
        () => {
            onDone();
        }
    );
    const updateMutation = useUpdateItemMutation(
        () => {
            setMsg({
                type: "success",
                // msg: configs.successMessage || "Updated Successfully",
                msg: "Updated successfully",
            });
            onSuccess();
        },
        () => {
            setMsg({ type: "error", msg: "Error Creating" });
            onError();
        },
        () => {
            onDone();
        }
    );

    function resetForm() {
        log.p("Resetting form...");
        formRef.resetFields();
    }

    log.d(form, "Form Obj");
    log.d(data, "data in QFormStandard");

    const submitForm = (data) => {
        log.d(init_data, "Init data");
        log.d(data, "Form Data");

        let refined_data = {};

        if (record_id) {
            const changed_keys = getChangedKeys(init_data, data);
            log.d(changed_keys, "Changed keys");

            fields.forEach((f) => {
                // if (changed_keys.includes(f.name)) {
                if (changed_keys.includes(f.name) || f.name === "images") {
                    refined_data = {
                        ...refined_data,
                        ...refineData(f, data),
                    };
                }
            });
            log.d(refined_data, "Update out");
        } else {
            fields.forEach((f) => {
                refined_data = {
                    ...refined_data,
                    ...refineData(f, data),
                };
            });
        }

        let out = {};
        if (form.action?.out) {
            out = encodeRecord(form.action?.out, refined_data);
        } else {
            out = refined_data;
        }
        log.d(out, "OUT");
        // return;

        if (record_id) {
            updateMutation.mutate({
                // form.table, record_id
                collection: form.table,
                id: record_id,
                data: out,
            });
        } else {
            createMutation.mutate({
                collection: form.table,
                data: out,
            });
        }
    };

    log.d(data, "initialValues in QFormStandard");
    log.d(fields, "Fields in QFromStandrard");

    if (record_id && Object.keys(data).length === 0) {
        return <h4>Loading data....</h4>;
    }
    return (
        <Form
            form={formRef}
            layout="vertical"
            style={{
                // maxWidth: "500px",
                ...wrapper_style,
            }}
            initialValues={data} //modify it
            onFinish={(values) => {
                log.d(values, "Submitting form now");
                submitForm(values);
            }}
        >
            {fields.map((f) => {
                return (
                    <QFieldStandard
                        key={f.name}
                        field={f}
                        value={data[f.name] || f.default_value}
                        // value={data[f.name]}
                        setValue={(newVal) =>
                            setData({
                                ...data,
                                [f.name]: newVal,
                            })
                        }
                        formData={data}
                        setFormData={setData}
                        org={org}
                    />
                );
            })}
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        {createMutation.isLoading || updateMutation.isLoading
                            ? configs.submittingButtonText ||
                            "Submitting form.."
                            : configs.submitButtonText || "Submit"}
                    </Button>
                    <Button
                        htmlType="reset"
                    // onClick={onReset}
                    >
                        Reset
                    </Button>
                </Space>
            </Form.Item>

            <Space>
                {msg.type && <Alert message={msg.msg} type={msg.type} />}
            </Space>
        </Form>
    );
}

export default QFormStandard;

function getChangedKeys(obj1, obj2) {
    let out = [];

    for (let key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
                out.push(key);
            }
        }
    }
    return out;
}
