import { Button } from "antd";
import { useItemsFine } from "../../../_actions/app/items";
import { getImageURL, getPublicImageURL } from "../../../_helpers/image";
import styles from "../styles/BrandStyles.module.css";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import AddBrandPersonal from "./AddBrandPersonal";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../actions/auth";

export default function BrandPersonal({ prefill = {} }) {
    const { isLoading: loadingMe, isSuccess: successMe, data: dataMe } = useCurrentUser();
    const [show, setShow] = useState(false);
    const { data, isSuccess, isLoading } = useItemsFine("qdb_person",
        "filter[user][_eq]=$CURRENT_USER", true);
    const [selected, setSelected] = useState(null);
    const history = useHistory();

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isSuccess) {
        return <p>Something went wrong</p>;
    }
    return (
        <div className={styles.container}>
            <Button
                icon={<PlusOutlined />}
                onClick={() => {
                    // setSelected(null);
                    // setShow(true);
                    history.push(`/settings/person/create`, {
                        from: history.location.pathname,
                    });

                }}
            >
                Add New Personal Brand
            </Button>
            <AddBrandPersonal
                show={show}
                setShow={setShow}
                initialValues={selected || {}}
                key={`shivam_${Math.random()}`}
            />
            {isSuccess && data?.length === 0 && (
                <p className={styles.no_data}>No personal brands created. Create now</p>
            )}
            <div className={styles.grid_wrapper}>
                {data.map((item) => {
                    const is_owner = item.user === dataMe?.id;
                    return (
                        <div
                            className={styles.item_wrapper}
                            key={item.id}
                            onClick={() => {
                                if (is_owner) {
                                    // setSelected(item);
                                    // setShow(true);
                                    history.push(`/settings/person/${item.id}/edit`, {
                                        from: history.location.pathname,
                                    });
                                }
                            }}
                            style={{
                                cursor: is_owner ? "pointer" : "default",
                                backgroundColor: is_owner ? "white" : "#f3f3f3"
                            }}
                        >
                            <img
                                className={styles.img_personal}
                                src={getPublicImageURL(item.profile_photo_formal)}
                                alt={item.full_name}
                            />
                            <p className={styles.name}>{item.full_name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
