import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import { Button, Input } from "antd";
import { useDebounce } from "../utils/input";
import { useItemsFine } from "../actions/app/items";
import log from "cslog";
import { getImageFileURL, getPublicImageURL } from "../_helpers/image";
import styles from "./styles/MyCollections.module.css";
import classNames from "classnames";
import Icon from "@ant-design/icons";
import CreationsSelection from "./CreationsSelection";

/**
 * CreationsPicker is a React component that allows users to search and select multiple creations
 * from a list of available items. The component uses a modal to display the list of creations, 
 * which can be filtered by a search term. Selected creations are managed in a local state and 
 * can be added or removed by the user. The component makes use of debouncing for the search 
 * input to optimize performance and fetches data using a custom hook.
 */
function CreationsPicker({ value, onChange }) {
    const [open, setOpen] = useState(false);
    const [search_term, setSearchTerm] = useState("");
    const search_query = useDebounce(search_term, 1000);
    const { isSuccess, isLoading, data } = useItemsFine(
        "qdb_creation_template",
        // `fields=id,title,tags.qdb_tag_id.name&limit=10&sort=-date_created`
        `fields=id,title,preview&filter[tags][qdb_tag_id][name][_contains]=${search_query}&sort=-date_created`
    )
    const [selected_creations, setSelectedCreations] = useState([]);

    log.d(data, "Creation picker data")

    useEffect(() => {
        setSelectedCreations([])
        setSearchTerm("")
    }, [value])

    const selectCreation = (creation_id) => {
        if (selected_creations.includes(creation_id)) {
            setSelectedCreations(selected_creations.filter(item => item !== creation_id));

        } else {
            setSelectedCreations([...selected_creations, creation_id]);
        }
    }


    return (
        <>
            <div className={styles.button_group}>
                <Button type="primary" ghost onClick={() => setOpen(true)}>
                    Add Templates
                </Button>
                <Button type="primary" ghost onClick={() => setOpen(true)}>
                    Add Creations
                </Button>
            </div>
            <CreationsSelection value={value} onChange={onChange} />
            <Modal
                title="Select Creations"
                open={open}
                width={"60vw"}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button
                        type="primary"
                        disabled={selected_creations.length === 0}
                        onClick={() => {
                            // onChange(selected_creations);
                            // onChange(selected_creations.map(item => ({
                            //     collection: "qdb_creation_template",
                            //     id: item
                            // })));
                            onChange([
                                ...(value?.length > 0 ? value : []),
                                ...(selected_creations?.length > 0 ? selected_creations.map(item => ({
                                    collection: "qdb_creation_template",
                                    id: item
                                })) : [])
                            ])
                            setOpen(false);
                        }}>Add {selected_creations.length} Creations</Button>,
                    <Button type="default" onClick={() => setSelectedCreations([])}>Reset</Button>,
                    <Button type="default" onClick={() => setOpen(false)}>Cancel</Button>,
                ]}
            >
                <Input
                    value={search_term}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    suffix={<Icon type="user" />}
                />
                {isSuccess && <div className={styles.creation_picker_wrapper}>
                    {data.map(item => <CreationCard item={item}
                        selected={selected_creations.includes(item.id)}
                        setSelected={selectCreation}
                    />)}</div>}
            </Modal>
        </>
    )
}

export default CreationsPicker

function CreationCard({ item, selected, setSelected }) {
    return <div className={classNames(styles.creation_item, { [styles.selected]: selected })}
        onClick={() => setSelected(item.id)}
    >
        <img src={getPublicImageURL(item.preview)} alt="preview" />
        <div className={styles.creation_details}>
            <h4>{item.title}</h4>
        </div>
    </div>
}