import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Upload } from "antd";
import log from "cslog";
import React from "react";
import { Redirect } from "react-router";
import { useCreateOrg } from "../actions/org";
import CenterCard2Part from "../_layouts/CenterCard2Part";
import { useMe } from "../actions/auth";
import Loading from "../common/Loading";

const OrgSetup = () => {
    const [form] = Form.useForm();
    const { isLoading, isSuccess, data } = useMe();
    const createMutation = useCreateOrg(() => { });

    if (isLoading) {
        return <Loading />;
    }

    if (isSuccess && data?.data?.organizations?.length > 0) {
        return (
            <Redirect
                to={{
                    pathname: "/",
                }}
            />
        );
    }

    return (
        <CenterCard2Part title="Organization Setup">
            <Form
                form={form}
                layout="vertical"
                requiredMark="optional"
                name="normal_login"
                style={{
                    maxWidth: "300px",
                }}
                initialValues={{
                    name: `${data?.data?.first_name} ${data?.data?.last_name} Personal`,
                    is_personal: true,
                }}
                onFinish={(values) => {
                    log.d(values, "Valeus");
                    createMutation.mutate({
                        ...values,
                        id: data?.data?.email,
                        users: [{ directus_users_id: data?.data?.id }],
                        is_personal: true,
                    });
                }}
            >
                <Alert message="You can change these later" type="info" />
                <Form.Item
                    label="Organization Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please enter org name",
                        },
                    ]}
                // hasFeedback
                >
                    <Input
                        placeholder="Organization Name"
                    //  size="large"
                    />
                </Form.Item>
                <Form.Item label="Organization Description" name="description">
                    <Input.TextArea
                        // showCount
                        // maxLength={100}
                        placeholder="Organization Description"
                    />
                </Form.Item>
                <Form.Item
                    label="Organization Logo"
                    valuePropName="fileList"
                // getValueFromEvent={normFile}
                >
                    <Upload action="/upload.do" listType="picture-card">
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    // loading={mutation.isLoading}
                    >
                        Proceed
                        <ArrowRightOutlined />
                    </Button>
                </Form.Item>
            </Form>
        </CenterCard2Part>
    );
};

export default OrgSetup;
