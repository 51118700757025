import Icon from "@ant-design/icons";
import { Menu } from "antd";
import log from "cslog";
import startCase from "lodash/startCase";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import NavHeader from "../common/NavHeader";
import DynamicScreenLoader from "./dynamic/DynamicScreenLoader";
import Logo from "../common/Logo";

const QurealSidebar = ({ app, onLoad, dscreens, onMenuClick = () => { } }) => {
    const match = useRouteMatch("/:app/:screen");
    const nav_layout = app.layout.navigation;

    log.d(app, "App in QurealSidebar")

    const menu = (
        <Menu
            // theme={nav_layout.theme}
            defaultSelectedKeys={[match?.params?.screen]}
            mode={nav_layout.type === "topbar" ? "horizontal" : "inline"}
            style={{
                // flex: 1,
                height: "100%",
                borderRight: "1px solid var(--border-color)",
            }}
        >
            {/* <Logo /> */}
            <h3
                style={{
                    padding: "10px 10px 10px 25px",
                }}
            >
                {app.name}
            </h3>
            {app.screens
                .sort((a, b) => a.sort - b.sort)
                .map((item, index) => {
                    // const stype = item.layout.type;
                    const screen_type = item.type;
                    if (
                        (screen_type === "static" ||
                            screen_type === "qmade" ||
                            screen_type === "qbuilt") &&
                        item.status === "published"
                    ) {
                        return (
                            <Menu.Item
                                key={item.slug}
                                icon={
                                    <Icon
                                        component={() => (
                                            <i className={item.icon}></i>
                                        )}
                                    />
                                }
                                onClick={onMenuClick}
                            >
                                <Link to={`/${app.slug}/${item.slug}`}>
                                    {item.name}
                                </Link>
                            </Menu.Item>
                        );
                    } else if (screen_type === "dynamic") {
                        if (!(`id_${item.id}` in dscreens)) {
                            return (
                                <DynamicScreenLoader
                                    item={item}
                                    onLoad={onLoad}
                                />
                            );
                        }
                        // return <DynamicMenu item={item} />;
                    }
                })}
            {Object.values(dscreens).map((screen) => {
                return screen.items.map((item) => {
                    return (
                        <Menu.Item
                            key={item.slug}
                            icon={
                                <Icon
                                    component={() => (
                                        <i className="fas fa-coffee"></i>
                                    )}
                                />
                            }
                        >
                            <Link
                                to={`/${app.slug}/${screen.slug}/${item.slug}`}
                            >
                                {startCase(item.name)}
                            </Link>
                        </Menu.Item>
                    );
                });
            })}
        </Menu>
    );

    if (app.screens.length <= 0) {
        return <></>;
    } else if (nav_layout.type === "topbar") {
        return <NavHeader theme={nav_layout.theme}>{menu}</NavHeader>;
    } else {
        return menu;
    }
};

export default QurealSidebar;
