import React, { useEffect, useState } from "react";
import log from "cslog";
import { Button, Form, Input, Space } from 'antd';
import OrgPicker from "./OrgPicker";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import { BulbOutlined } from "@ant-design/icons";

const { TextArea } = Input;


function TemplateAIInput({ inputs = [], used_schemas = [], onAISubmit, me, creation_type, org, onOrgChange, person, onPersonChange, isMobile, loadingAI }) {
    const [schemas, setSchemas] = useState({})


    useEffect(() => {
        if (used_schemas.length > 0) {
            const out = {}
            used_schemas.forEach((schema) => {
                out[schema.qdb_creation_schema_id.id] = schema.qdb_creation_schema_id
            })
            setSchemas(out);
        }
    }, [used_schemas])

    const page_id = creation_type === "document" ? "home" : "frontSide";

    const pageInputs = inputs?.[page_id]

    log.d(schemas, "Schemas in PreviewTemplate");

    if (!pageInputs) {
        return <></>
    }
    return <div>
        <p>
            {Object.keys(pageInputs).map((input_id) => {
                return <SingleInput
                    key={input_id}
                    input_id={input_id}
                    input={pageInputs[input_id]}
                    schemas={schemas}
                    me={me}
                    org={org}
                    onOrgChange={onOrgChange}
                    person={person}
                    onPersonChange={onPersonChange}
                    isMobile={isMobile}
                    onAISubmit={onAISubmit}
                    loadingAI={loadingAI}
                />
            })}
        </p>
    </div>
}

export default TemplateAIInput;


function SingleInput({ input_id, input, schemas, onAISubmit, me, org, onOrgChange, person, onPersonChange, isMobile, loadingAI }) {
    switch (input.block_type) {
        case "qureal_database":
            // return <p>DB: {JSON.stringify(input)}</p>
            return <DBInput input_id={input_id} input_data={input?.data} me={me} isMobile={isMobile}
                org={org} onOrgChange={onOrgChange}
                person={person} onPersonChange={onPersonChange}
            />
        case "qureal_preset":
            return <PresetInput input_data={input?.data} schemas={schemas} onAISubmit={onAISubmit} loadingAI={loadingAI} />
        default:
            return <p className="q_gray">{input.block_type} not supported yet.</p>
    }
}


function PresetInput({ input_data, schemas, onAISubmit, loadingAI }) {
    const [form] = Form.useForm();

    const applied_schema = schemas?.[input_data?.qureal_schema_id]

    return <Form
        form={form}
        layout="vertical"
        // onFinish={onFinish}
        onFinish={(values) => {
            if (values?.user_prompt?.length > 3) {
                onAISubmit(values)
            }
        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        {/* <p>INPUT: {JSON.stringify(input_data)}</p>
        <p>APPLIED_Schema: {JSON.stringify(applied_schema)}</p> */}
        <Form.Item
            name="user_prompt"
            label={applied_schema?.user_prompt_question || "Enter input"}
            rules={[
                // { required: true }, 
                { type: 'string', max: 200 }
            ]}
        >
            <TextArea rows={4} placeholder="Enter mesage" />
        </Form.Item>
        <Form.Item>
            <Space>
                <Button type="primary" htmlType="submit" loading={loadingAI} icon={<BulbOutlined />}>
                    Generate
                </Button>
                <Button htmlType="button"
                // onClick={onFill}
                >
                    Reset
                </Button>
            </Space>
        </Form.Item>
    </Form>

}


function DBInput({ input_id, input_data, me, org, onOrgChange, person, onPersonChange, isMobile }) {

    let form_field = null;
    if (input_id === "current_organization") {
        form_field = <OrgPicker
            me={me}
            org={org}
            onOrgChange={onOrgChange}
            isMobile={isMobile}
        />
    } else if (input_id === "current_person") {
        form_field = <RelationFieldStanard
            field={{
                foreign_key_table: "qdb_person",
                template: "{{full_name}}",
                foreign_key_column: "id"
            }}
            value={person}
            onChange={new_person => onPersonChange(new_person)}
            style={{
                width: 200,
                marginTop: "15px"
            }}
            showSearch={!isMobile}
        />
    }
    return <Form layout="vertical">

        <Form.Item name="organization" label="Organization">
            {form_field}
        </Form.Item>
    </Form>
}