import styles from "./styles/QurealSpotlight.module.css";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Input } from "antd"
import { SearchOutlined, ArrowRightOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useCreateItemMutation, useItemsFine } from "../actions/app/items"
import log from "cslog"
import { getImageFileURL, getImageURL } from "../_helpers/image";
import PreviewTemplate from "../qmade/creator/PreviewTemplate";
import useMedia from "react-use/lib/useMedia";
import { useDebounce } from "../utils/input";
import snakeCase from "lodash/snakeCase";
import { useGenerateTemplateSuggestions } from "../_actions/bot";


const FIELDS = "id,type,title,preview.id,preview.filename_disk,tags.qdb_tag_id.*,inputs,used_schemas.qdb_creation_schema_id.id,used_schemas.qdb_creation_schema_id.name,used_schemas.qdb_creation_schema_id.user_prompt_question"

function QurealLightCore({ me, open, setOpen, isMobile, selected_item, setSelectedItem, open_preview, setOpenPreview, prefill = null }) {
    const [search_query, setSearchQuery] = useState("");
    const debounced_query = useDebounce(search_query, 500)
    const [show_result, setShowResult] = useState(false);
    const inputRef = useRef(null);
    const [enable_generate, setEnableGenerate] = useState(false);
    const [gen_result, setGenResult] = useState([]);
    const [post_idea, setPostIdea] = useState(null);


    const { isSuccess: successTemplates, isLoading: loadingTemplates, data: dataTemplates } = useItemsFine(
        "qdb_creation_template",
        `fields=${FIELDS}&limit=4&filter[tags][qdb_tag_id][name][_contains]=${snakeCase(debounced_query)}`,
        !enable_generate
    );

    useEffect(() => {
        if (prefill) {
            setSearchQuery(prefill);
            mutationGen.mutate({ user_prompt: prefill });
        }
    }, [prefill])

    const mutationCreate = useCreateItemMutation(() => {
        log.p("query saved for research purpose.")
    }, () => { }, () => { });

    const mutationGen = useGenerateTemplateSuggestions(
        (result) => {
            log.d(result, "Result on Gen");
            if (result?.success) {
                const out = result?.data;
                if (out?.length > 0) {
                    setShowResult(true);
                    setGenResult(out);
                    setPostIdea(result?.post_idea)
                }
            }
            if (search_query?.length > 2) {
                mutationCreate.mutate({
                    collection: "qdb_gen_query",
                    data: {
                        query: search_query
                    }
                })
            }
            // setShowResult(false);
            // if (result?.suggestions?.length > 0) {
            //     setShowResult(true);
            // }
        },
        () => {

        })

    useEffect(() => {
        if (open) {
            setSelectedItem(null);
            inputRef.current?.focus({
                cursor: 'all',
            });
        }
    }, [open])

    useEffect(() => {
        if (!open_preview && selected_item?.type) {
            setOpen(true);
        }
    }, [open_preview])

    useEffect(() => {
        if (search_query.length > 0) {
            if (search_query.includes(" ")) {
                setEnableGenerate(true);
                setShowResult(false);
            } else {
                setEnableGenerate(false);
                setShowResult(true);
            }
        } else {
            setEnableGenerate(false);
            setShowResult(false);
        }
    }, [search_query])

    log.d(dataTemplates, "Data Templates in QuraelLight");


    const generateSuggestions = () => {
        log.p("Generating Suggestions");
        mutationGen.mutate({ user_prompt: search_query });
    }




    return <Fragment>
        <div className={styles.work_area} onClick={(e) => e.stopPropagation()}>
            {/* <h4>Qureal Spotlight</h4> */}

            {/* <Input
                size="large"
                placeholder="What do you want to create?"
                ref={inputRef}
                className={styles.search_box}
                value={search_query}
                onChange={(e) => setSearchQuery(e.target.value)}
            /> */}

            <div className={styles.search_box_wrapper}>
                {enable_generate ? <ThunderboltOutlined style={{
                    fontSize: "1.2rem",
                    color: "#0000003a",
                    paddingLeft: "5px",
                    paddingRight: "10px"
                }} /> :
                    <SearchOutlined style={{
                        fontSize: "1.2rem",
                        color: "#0000003a",
                        paddingLeft: "5px",
                        paddingRight: "10px"
                    }} />}
                <input
                    size="large"
                    placeholder="What do you want to create?"
                    ref={inputRef}
                    className={styles.search_box_input}
                    value={search_query}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            generateSuggestions();
                        }
                    }}
                />
                {enable_generate && !isMobile && <Button loading={mutationGen.isLoading} type="primary" onClick={generateSuggestions}>Generate</Button>}
                {enable_generate && isMobile && <Button loading={mutationGen.isLoading} type="primary" icon={<ArrowRightOutlined />} onClick={generateSuggestions} />}
            </div>

            {show_result && <div className={styles.results_wrapper}>

                {!enable_generate && loadingTemplates && <div className={styles.blank_wrapper}>Searching...</div>}
                {successTemplates && dataTemplates?.length === 0 && <div className={styles.blank_wrapper}>No template found</div>}
                {successTemplates && <div className={styles.grid_wrapper}>
                    {dataTemplates?.map((item) => {
                        return <img
                            src={getImageFileURL(item?.preview?.filename_disk)}
                            alt="preview"
                            className={styles.preview}
                            onClick={() => {
                                setOpenPreview(true);
                                setSelectedItem({
                                    type: "template",
                                    data: item
                                })
                            }}
                        />
                    })}
                </div>}

                {gen_result?.length > 0 && <div className={styles.grid_wrapper}>
                    {gen_result?.map((item) => {
                        return <img
                            src={getImageFileURL(item?.preview?.filename_disk)}
                            alt="preview"
                            className={styles.preview}
                            onClick={() => {
                                setOpenPreview(true);
                                setSelectedItem({
                                    type: "template",
                                    data: item
                                })
                            }}
                        />
                    })}
                </div>}
            </div>}
        </div>
        {selected_item?.type === "template" && <PreviewTemplate
            // key={selected_index}
            // trigger={trigger}
            open={open_preview}
            setOpen={(val) => {
                if (val) {
                    setOpenPreview(true);
                } else {
                    setOpenPreview(false);
                    setSelectedItem(null);
                }
            }}
            is_template={true}
            slug_field="id"
            isMobile={isMobile}
            item={selected_item?.data}
            enable_nav={false}
            me={me}
            post_idea={post_idea}
        />}

    </Fragment>
}

export default QurealLightCore;