import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { useEventCalender } from "../actions/events";
import log from "cslog";
import styles from "./styles/ContentCalender.module.css";
import { PreviewTemplateList } from "../qmade/creator/PreviewTemplateList";
import { getImageFileURL, getImageURL, getPublicImageURL } from "../_helpers/image";
import { Button } from "antd";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const ASSETS_GROUPS = [
    {
        id: "facebook",
        title: "Facebook",
        icon: "fab fa-facebook",
        color: "#1877f2",
        assets: [
            {
                id: "facebook_cover",
                title: "Facebook Cover"
            }
        ]
    },
    {
        id: "linkedin",
        title: "LinkedIn",
        icon: "fas fa-linkedin",
        color: "#0a66c2",
        assets: [
            {
                id: "linkedin_cover",
                title: "LinkedIn Cover"
            }
        ]
    }
]

function BrandAssets({ me = {} }) {
    const { isSuccess, isLoading, data } = useEventCalender(true);
    const [open, setOpen] = useState(false)
    const [selected_card, setSelectedCard] = useState(null);





    return (
        <QurealSection header={<SectionHeader
            heading="Brand Assets"

        >
            {/* <Button type="primary" >Add Event</Button> */}
        </SectionHeader>}>
            {/* <PreviewTemplateList
                    tags={["gandhi"]}
                    enable={true}
                    open={true}
                    setOpen={setOpen}
            /> */}
            <div className={styles.wrapper}>
                <p>All assets required for one time setup</p>

                {ASSETS_GROUPS.map((group, index) => (
                    // <MonthSection month={month} data={data[index]}
                    <GroupSection
                        group={group}
                        key={index}
                    // onCardClick={(item) => {
                    //     log.d(item, "Item clicked card")
                    //     setOpen(true)
                    //     setSelectedCard(item)
                    // }}
                    />
                ))}
            </div>
            {selected_card && <PreviewTemplateList
                // enable={open && selected_card?.filter !== null}
                enable={open}
                // filter={selected_card?.filter ? encodeURIComponent(JSON.stringify(selected_card.filter)) : null}
                filter={selected_card?.filter ? JSON.stringify(selected_card.filter) : null}
                open={open}
                setOpen={setOpen}
                me={me}
            />}
        </QurealSection>
    );
}



function GroupSection({ group, onCardClick }) {

    return (
        <div className={styles.month_wrapper}>
            <h5 className={styles.month_name}>{group.title}</h5>
            {group.assets.length > 0 && (
                <div className={styles.items_wrapper}>
                    {group.assets.map((item) => (
                        <OneCard item={item} onCardClick={onCardClick} />
                    ))}
                </div>
            )}
        </div>
    );
}

function OneCard({ item, onCardClick }) {
    return (
        <div className={styles.item_wrapper} onClick={() => onCardClick(item)}
            style={item.feature_image ? {
                backgroundImage: `url(${getPublicImageURL(item.feature_image)})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
            } : {}}
        >
            <p className={styles.item_title}>{item.name}</p>
            {/* <p>{item.date}</p> */}
            <div className={styles.item_dt_wrapper}>
                <p className={styles.item_dt}>{item.dt?.getDate()}</p>
                {/* <p className={styles.item_month_name}>{month}</p> */}
            </div>
        </div>
    );
}

export default BrandAssets;
