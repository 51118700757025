import Handlebars from "handlebars";
import React, { useEffect, useState } from "react";
import { useItemsFine } from "../../actions/app/items";
// import Dropdown from "./Dropdown";
// import MultiDropown from "./MultiDropdown";
import { Select } from "antd";
import log from "cslog";

const { Option } = Select;

function RelationFieldStanard({ field, value, onChange, many = false, style = {}, showSearch = true, fieldString = "", additional_options = [] }) {
    const {
        data: dataItems,
        isLoading: loadingItems,
        isSuccess: successItems,
    } = useItemsFine(
        field.foreign_key_table,
        // `limit=1000&sort=-${field.foreign_key_column}`,
        fieldString,
        field.foreign_key_table !== undefined
    );

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (dataItems) {
            console.log("dataItems", dataItems);
            const option_template = Handlebars.compile(field.template);

            const newOptions = [];
            if (field.type === "relationM2M") {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template({
                            [field.junction_field]: item,
                        }),
                        value: item[field.foreign_key_column], //it should be id (m2m, o2m)
                    });
                });
            } else if (field.type === "relationO2M") {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template(item),
                        // value: item[field.foreign_key_column], //it should be id (m2m, o2m)
                        value: item[field.many_table_pk || "id"],
                    });
                });
            } else {
                dataItems.forEach((item) => {
                    newOptions.push({
                        label: option_template(item),
                        value: item[field.foreign_key_column],
                    });
                });
            }
            setOptions(newOptions);
        }
    }, [dataItems]);

    // console.log("Options", options);

    const filterOption = (input, option) => {
        console.log(`Matching ${input}`, option);
        return (option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase());
    };

    if (many) {
        return (
            <Select
                mode="multiple"
                allowClear
                onChange={(val) => onChange(val)}
                defaultValue={value}
                showSearch={showSearch}
                filterOption={filterOption}
                options={options}
                style={style}
            />
        );
    }

    return (
        <Select
            allowClear
            onChange={(val) => onChange(val)}
            defaultValue={value}
            showSearch={showSearch}
            filterOption={filterOption}
            options={options}
            style={style}
        />
    );
    // return <Dropdown options={options} value={value} onChange={onChange} />;
}

export default RelationFieldStanard;
