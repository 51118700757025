import React, { useEffect, useState } from "react";
import { useItemsFine } from "../actions/app/items";
import log from "cslog";
import { getPublicImageURL } from "../_helpers/image";


function CreationsSelection({ value, onChange }) {
    const [ids_to_fetch, setIdsToFetch] = useState([]);
    const { isSuccess, isLoading, data } = useItemsFine(
        "qdb_creation_template",
        `fields=id,title,preview&sort=-date_created&filter[id][_in]=${ids_to_fetch?.length > 0 ? ids_to_fetch.join(",") : ""}`,
        ids_to_fetch && ids_to_fetch.length > 0
    )


    useEffect(() => {
        if (value && value.length > 0) {
            if (value.some(item => ids_to_fetch.indexOf(item.id) === -1)) {
                log.p("changing ids to fetch");
                log.d(value, "Value");
                log.d(ids_to_fetch, "Ids to fetch");
                setIdsToFetch(value.map(item => item.id));
            }
        }
    }, [value])


    log.d(data, "Creation selection data")


    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "10px",
            paddingTop: "15px",
            paddingBottom: "15px"
        }}>
            {isSuccess && value?.length > 0 && value.map(item => {
                const creation = data.find(creation => creation.id === item.id)
                return <div
                    style={{
                        width: "150px",
                        height: "150px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "5px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        backgroundColor: "#f4f1f1",
                        outline: "1px solid transparent",
                        position: "relative",
                        overflow: "hidden",
                    }}
                >
                    <img src={getPublicImageURL(creation?.preview)}
                        style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "contain"
                        }}
                    />
                    <div style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        cursor: "pointer",
                        backgroundColor: "red",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                        onClick={() => onChange(value.filter(item => item.id !== creation.id))}
                    >x</div>
                </div>
            })}
        </div>
    );
}

export default CreationsSelection;