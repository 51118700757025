import React from "react";

function CollectionPreview({ }) {
    return (
        <div>
            <h3>Collection Preview</h3>
        </div>
    );
}


export default CollectionPreview