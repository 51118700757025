import styles from "./styles/TopBar.module.css";
import React from "react";
import { TOPBAR_HEIGHT } from "../constants";
import Logo from "./Logo";
import TopBarOptions from "./TopBarOptions";
import { useCurrentUser } from "../actions/auth";
import { Layout, theme } from "antd";
import log from "cslog";
import useMedia from "react-use/lib/useMedia";
import { useAtom } from "jotai";
import { sidebarAtom } from "../states/navigation";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

function TopBar({ me = {} }) {
    const isMobile = useMedia("(max-width: 700px)");
    const [open_sidebar, setOpenSidebar] = useAtom(sidebarAtom);
    const {
        token: { colorBgContainer, headerBg },
    } = theme.useToken();
    const tt = theme.useToken();

    // console.log("Token", tt);
    // console.log("Theme", theme);

    log.d(me, "Me Data");
    return (
        <Header
            className={styles.wrapper}
            style={{
                height: TOPBAR_HEIGHT,
                background: colorBgContainer,
            }}
            theme="light"
        >
            <div className={styles.left_items}>
                {isMobile && (
                    <MenuOutlined onClick={() => setOpenSidebar(!open_sidebar)} />
                )}

                <Logo />
            </div>
            {/* <h3>Options like Shopify</h3> */}

            <TopBarOptions
                name={`${me?.first_name} ${me?.last_name}`}
                selected_org={me?.selected_org}
                orgs={me?.organizations?.map(
                    (item) => item.qdb_organization_id
                )}
                isMobile={isMobile}
                me={me}
            />
        </Header>
    );
}

export default TopBar;
