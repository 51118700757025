import React from "react";
import { Layout } from "antd";
import styles from "./styles/QurealSection.module.css";

const { Content } = Layout;

function QurealSection({ header, children, header_sticky = true }) {
    return (
        <div className={styles.wrapper}>
            <Content className={styles.content}>
                <div
                    style={{
                        position: header_sticky ? "sticky" : "inherit",
                    }}
                    className={styles.header_wrapper}
                >
                    {header}
                </div>
                {children}
            </Content>
            <p style={{ textAlign: "center" }}>Made with ❤️ in India 🇮🇳</p>
        </div>
    );
}

export default QurealSection;
