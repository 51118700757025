import React from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";

import log from "cslog"
import CreationsPicker from "./CreationsPicker";

function CollectionForm() {
    const [form] = Form.useForm();



    return <Form
        layout="vertical"
        form={form}
        // onFinish={addOrganization}
        initialValues={{
            // ...initialValues,
            // ...data,
            // ...(logo_hori && { logo_horizontal: logo_hori }),
        }}
        onFinish={(values) => {
            log.d(values, "Submitting collection form")
            //
        }}
    >
        <Form.Item label="Name" name="name">
            <Input />
        </Form.Item>

        <Form.Item
            label="Description"
            name="description"
        >
            <TextArea rows={4} />
        </Form.Item>
        <Form.Item
            label="Add Templates and Creations"
            name="creations"
        >
            <CreationsPicker />
        </Form.Item>


        <Form.Item>
            <Button type="primary" htmlType="submit">
                Save Collection
            </Button>
        </Form.Item>
    </Form>
}

export default CollectionForm;