import styles from "./styles/TemplatesManager.module.css";
import React, { useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import TemplatesGrid from "./TemplatesGrid";
import useMedia from "react-use/lib/useMedia";
import { Segmented } from "antd";


function TemplatesManager({ me }) {
    const isMobile = useMedia("(max-width: 700px)");
    const [status, setStatus] = useState("Draft")

    return <QurealSection header={<SectionHeader heading="Templates Manager" />}>
        <div className={styles.wrapper}>
            <Segmented
                options={["Draft", "Published"]}
                value={status}
                onChange={setStatus}
            />
            <TemplatesGrid me={me} isMobile={isMobile} status={status.toLocaleLowerCase()} />
        </div>
    </QurealSection>
}

export default TemplatesManager;