import React, { useEffect, useState } from "react";
import QurealSection from "../common/QurealSection";
import SectionHeader from "../components/commons/SectionHeader";
import { useEventCalender } from "../actions/events";
import log from "cslog";
import styles from "./styles/ContentCalender.module.css";
import { PreviewTemplateList } from "../qmade/creator/PreviewTemplateList";
import { getImageFileURL, getImageURL, getPublicImageURL } from "../_helpers/image";
import { Badge, Button } from "antd";
import { Calendar } from 'antd';
import { Dayjs } from 'dayjs';
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { useItemsFine } from "../actions/app/items";
import classNames from "classnames";

import moment from 'moment'

moment.updateLocale('en', {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
});


const ALL_EVENTS = {
    1: [{ title: "Hello One" }],
    3: [{ title: "Hello Three" }, { title: "Nice Three" }],
    10: [{ title: "Hello Ten" }],
}

// items format 
// {month: {day: item_data}}


const ITEM_TYPE_CLASS_MAPPING = {
    "festival": styles.festival,
    "value": styles.value,
    "meme": styles.meme
}


function ContentCalenderNew({ me = {} }) {
    // const { isSuccess, isLoading, data } = useEventCalender(true);
    const { isSuccess: successEvent, isLoading: loadingEvent, data: dataEvent } = useItemsFine("qdb_event", "filter[status][_eq]=published", true, false);
    const { isSuccess: successEntries, isLoading: loadingEntries, data: dataEntries } = useItemsFine("qdb_calendar_entries", "", true, false);

    const [status, setStatus] = useState({
        event: false,
        entry: false
    })
    const [items, setItems] = useState({})


    const [open, setOpen] = useState(false)
    const [selected_card, setSelectedCard] = useState(null);

    // log.d(dataEvent, "Event Data in CCN")
    // log.d(dataEntries, "Entries Data in CCN")

    useEffect(() => {
        if (successEvent && !status.event) {
            log.h3("Processing Events")
            setItems(out => {
                dataEvent.forEach((item) => {
                    const dt = item.date ? new Date(item.date) : null;
                    if (dt) {
                        const month = dt.getMonth();
                        const day = dt.getDate();
                        const out_month = out[month] || {};
                        const out_day = out_month[day] || [];
                        out[month] = {
                            ...out_month,
                            [day]: [
                                ...out_day,
                                { ...item, dt: dt, title: item.name, type: "festival" }
                            ]
                        }
                    }
                });
                log.d(out, "Processed events in CCN")
                return out;
            });
            setStatus({
                ...status,
                event: true,
            })
        }
    }, [successEvent, dataEvent])

    useEffect(() => {
        if (successEntries && !status.entry) {
            setItems(out => {
                dataEntries.forEach((item) => {
                    const dt = item.date ? new Date(item.date) : null;
                    if (dt) {
                        const month = dt.getMonth();
                        const day = dt.getDate();
                        const out_month = out[month] || {};
                        const out_day = out_month[day] || [];
                        out[month] = {
                            ...out_month,
                            [day]: [
                                ...out_day,
                                { ...item, dt: dt, title: item.idea?.title || "Entry", type: item.idea?.type || "entry" }
                            ]
                        }
                    }
                });
                log.d(out, "Processed entries in CCN")
                return out;
            });
            setStatus({
                ...status,
                entry: true
            })
        }
    }, [successEntries, dataEntries])


    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    return (
        <QurealSection
        // header={<SectionHeader
        //     heading="Content Calender New"
        // >
        //     <Button type="primary" >Add Event</Button>
        // </SectionHeader>}
        >
            <div className={styles.wrapper}>
                {<Calendar
                    locale={{
                        // https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json
                        lang: {
                            "locale": "en_US",
                            // "shortWeekDays": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                            "shortWeekDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                        }
                    }}
                    onPanelChange={onPanelChange}
                    // headerRender={({ value, type, onChange, onTypeChange }) => {
                    //     return <div>
                    //         <h3>Header</h3>
                    //     </div>
                    // }}
                    headerRender={({ value: current, type, onChange, onTypeChange }) => {
                        log.d(current, "Value in headerRender")
                        return <div className={styles.header}>
                            {/* <Button.Group>
                                <Button onClick={() => onTypeChange('month')}>Month</Button>
                                <Button onClick={() => onTypeChange('week')}>Week</Button>
                                <Button onClick={() => onTypeChange('day')}>Day</Button>
                            </Button.Group> */}

                            <Button.Group>
                                <Button shape="circle" type="text" icon={<LeftOutlined />} onClick={() => onChange(current.add(-1, type))} />
                                <Button shape="circle" type="text" icon={<RightOutlined />} onClick={() => onChange(current.add(1, type))} />
                            </Button.Group>
                            <div className={styles.header_title}>
                                Content Calender{" "}
                                {type === 'month' ? current.format('MMMM YYYY') : null}
                                {type === 'week' ? `Week ${current.week()} ${current.format('MMM YYYY')}` : null}
                                {type === 'day' ? current.format('dddd, MMMM D, YYYY') : null}
                            </div>
                        </div>
                    }}
                    cellRender={(current, info) => {
                        const listData = items[current.month()]?.[current.date()] || []
                        return <div
                            className={styles.list}
                        >
                            {listData.map((item) => (
                                <li
                                    key={item.title}
                                    className={classNames(styles.list_item, ITEM_TYPE_CLASS_MAPPING[item.type])}
                                    onClick={() => {
                                        setOpen(true)
                                        setSelectedCard(item)
                                    }}
                                >
                                    {item.title}
                                </li>
                            ))}
                        </div>
                    }}
                />}
                {selected_card && <PreviewTemplateList
                    // enable={open && selected_card?.filter !== null}
                    enable={open}
                    // filter={selected_card?.filter ? encodeURIComponent(JSON.stringify(selected_card.filter)) : null}
                    filter={selected_card?.filter ? JSON.stringify(selected_card.filter) : null}
                    open={open}
                    setOpen={setOpen}
                    me={me}
                />}
            </div>
        </QurealSection>
    );
}

export default ContentCalenderNew;
