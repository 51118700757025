import React, { useState } from "react";
import { useSingleItem } from "../../../_actions/app/items";
import { List, Skeleton, Avatar, Form, Select, Button } from "antd"
import RelationFieldStanard from "../../../qforms/standard_elements/RelationFieldStandard";

const { Option } = Select;

function ManageBrandMembers({ id, members }) {
    const [new_colab, setNewColab] = useState({
        directus_users_id: null,
        // qp_creation_id: data.id,
        access: "full",
    });

    const list = members.map((item) => {
        return {
            name: `${item.directus_users_id.first_name} ${item.directus_users_id.last_name}`,
            email: item.directus_users_id.email,
            loading: false
        }
    })

    const addColab = () => {
        if (new_colab.directus_users_id) {
            //now add
            // createMutation.mutate({
            //     collection: "qp_creation_directus_users",
            //     data: new_colab,
            //     extraInvalidate: ["items", "qp_creation"],
            // });
        }
    };

    const deleteColab = (row_id) => {
        // deleteMutation.mutate({
        //     collection: "qp_creation_directus_users",
        //     id: row_id,
        //     extraInvalidate: ["items", "qp_creation"],
        // });
    };


    return <div style={{
        maxWidth: "600px",
    }}>
        {/* <Form layout="vertical" onFinish={(values) => {
            addColab();
        }}>
            <div style={{
                maxWidth: "500px",
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                gap: "5px"

            }}>
                <Form.Item label="Add New Collaborator" name="user">
                    <RelationFieldStanard
                        field={{
                            type: "relationM2O",
                            foreign_key_table: "directus_users",
                            foreign_key_column: "id",
                            template: "{{first_name}} {{last_name}}",
                        }}
                        many={false}
                        value={new_colab.directus_users_id}
                        onChange={(newVal) => {
                            setNewColab({
                                ...new_colab,
                                directus_users_id: newVal,
                            });
                        }}
                    />
                </Form.Item>

                <Form.Item label=" ">

                    <Button
                        type="primary"
                        htmlType="submit"
                    // loading={createMutation.isLoading}
                    >
                        Add
                    </Button>
                </Form.Item>
            </div>
        </Form> */}

        <List
            className="demo-loadmore-list"
            loading={false}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={list}
            renderItem={(item) => (
                <List.Item
                    actions={[<a key="list-loadmore-edit">Remove</a>]}
                >
                    <Skeleton avatar title={false} loading={item.loading} active>
                        <List.Item.Meta
                            avatar={<Avatar src={item.picture} />}
                            title={item.name}
                            description={item.email}
                        />
                    </Skeleton>
                </List.Item>
            )
            }
        />
    </div >;
}

export default ManageBrandMembers;