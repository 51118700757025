import React, { useEffect, useState } from "react";
import { useItemsFine } from "../../actions/app/items";
import log from "cslog";
import PreviewTemplate from "./PreviewTemplate";
import { Modal } from "antd";
import LoadingBox from "../../common/LoadingBox";
import useMedia from "react-use/lib/useMedia";
import { shuffle } from "../../_helpers/array";

export const PreviewTemplateList = ({ creation_type, tags = [], me, filter, open, setOpen, enable = false }) => {
    const { isSuccess, isLoading, data } = useItemsFine(
        "qdb_creation_template",
        filter ? `fields=id,type,title,preview.id,preview.filename_disk,tags.qdb_tag_id.*,inputs,used_schemas.qdb_creation_schema_id.id,used_schemas.qdb_creation_schema_id.name,used_schemas.qdb_creation_schema_id.user_prompt_question&filter=${filter}` : `fields=id,type,title,preview,tags.qdb_tag_id.*&filter={"tags":{"qdb_tag_id":{"name":{"_in":["${tags.join('","')}"]}}}}`,
        enable
    );
    const [selected_index, setSelectedIndex] = React.useState(null);
    const [list, setList] = useState([]);
    const isMobile = useMedia("(max-width: 700px)");

    useEffect(() => {
        if (isSuccess && data.length > 0) {
            setSelectedIndex(0);
            // setList(shuffle(data));
            const temp_arr = data;
            shuffle(temp_arr);
            setList(temp_arr);
        }
    }, [isSuccess, data])

    log.d(data, "PreviewTemplateList data");
    log.d(filter, "Filter in PreviewTemplateList")

    if (isLoading) {
        return <Modal open={open} footer={null} onCancel={() => setOpen(false)} >
            <LoadingBox />
        </Modal>
    } else if (isSuccess && data?.length === 0) {
        return <Modal open={open} footer={null} onCancel={() => setOpen(false)} >
            <p>No creation found for this</p>
        </Modal>
    }

    return (
        <div>
            <h1>PreviewTemplateList</h1>
            {/* <p>{JSON.stringify(data)}</p> */}
            {isSuccess && list.length > 0 && list.map(item => {
                return <div key={item.id}>
                    <h4>{item.title}</h4>
                    {/* <p>{item.tags.map(tag => (<span>{tag.qdb_tag_id.name}{", "}</span>))}</p> */}
                </div>
            })}
            {isSuccess && list.length > 0 && list[selected_index] &&
                <PreviewTemplate
                    // key={selected_index}
                    // trigger={trigger}
                    open={open}
                    setOpen={setOpen}
                    is_template={true}
                    slug_field="id"
                    enable_nav={true}
                    isMobile={isMobile}
                    item={list[selected_index]}
                    enable_prev={selected_index > 0}
                    enable_next={selected_index < list.length - 1}
                    onPrevClick={() => setSelectedIndex(selected_index - 1)}
                    onNextClick={() => setSelectedIndex(selected_index + 1)}
                    me={me}
                />}
        </div>
    );
}