import { Button, Checkbox, Drawer, Modal, Select } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { getTime } from "../../_helpers/app/dt";
import { getImageFileURL, getPublicImageURL } from "../../_helpers/image";
import { PREVIEW_CREATION_URL, PREVIEW_DOCUMENT_CREATION_URL, PREVIEW_DOCUMENT_TEMPLATE_URL, PREVIEW_TEMPLATE_URL } from "../../constants";
import RelationFieldStanard from "../../qforms/standard_elements/RelationFieldStandard";
import styles from "./styles/Preview.module.css";
import log from "cslog"
import { QurealViewer, useQurealActions } from "qureal-editor"

import { DownloadOutlined, ShareAltOutlined, EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import TemplateAIInput from "./TemplateAIInput";
import { useGenerateTemplatePreset } from "../../_actions/bot";
import GenerationLoading from "./GenerationLoading";



function PreviewTemplate({ item, trigger, slug_field = "id", is_template = false,
    enable_nav = false,
    enable_prev = false,
    enable_next = false,
    onPrevClick = () => { },
    onNextClick = () => { },
    open,
    setOpen,
    enable_img_preview = true,
    me = {},
    isMobile = false,
    post_idea = null
}) {
    const [iopen, setIOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [prefill, setPrefill] = useState({})

    const [org, setOrg] = useState(me?.selected_org?.id);
    const [person, setPerson] = useState(null);
    const [preview, setPreview] = useState(true);
    const [status, setStatus] = useState({
        download: false,
        share: false
    })

    const { actionRef, download, share } = useQurealActions();
    const mutationAIGen = useGenerateTemplatePreset((data) => {
        log.d(data, "AI Gen success")
        if (data?.success) {
            // send data.data to iframe
            const out = {}
            Object.keys(data.data).forEach((key) => {
                out[key] = data.data[key]["data"]
            })
            setPreview(out)
            setPrefill(out);
            log.p("data pushed to template")
        }
        // alert(`AI Gen success: ${JSON.stringify(data)}`)
    });

    useEffect(() => {
        if (iopen) {
            setLoading(true);
        } else {
            setLoading(false)
        }
    }, [iopen]);

    // useEffect(() => {
    //     window.onpopstate = (e) => {
    //         alert("back pressed")
    //         e.stopPropagation();
    //         if (open) {
    //             setOpen(false)
    //         }
    //     }
    // }, [])

    let url = `${is_template ? PREVIEW_TEMPLATE_URL : PREVIEW_CREATION_URL}${item[slug_field]}`
    if (item?.type === "document") {
        url = `${is_template ? PREVIEW_DOCUMENT_TEMPLATE_URL : PREVIEW_DOCUMENT_CREATION_URL}${item[slug_field]}`
    }


    const editor_url = `https://editor.qureal.com/${item?.id}/edit`
    let customized = false

    let iframe_url = `${url}?`;
    if (org) {
        iframe_url += `qi_organization=${org}`
        customized = true;
    }
    if (person) {
        iframe_url += `&qi_person=${person}`
        customized = true;
    }

    log.d(me, "Me in PreviewTemplate")
    log.d(item, "PreviewTemplate item")


    const onAISubmit = (data) => {
        // alert("submitting")
        const id_key = is_template ? "template_id" : "creation_id"
        const out = {
            ...data,
            [id_key]: item["id"]
        }
        log.d(out, "Submitting to AI")
        mutationAIGen.mutate(out)
    }


    useEffect(() => {
        if (post_idea) {
            onAISubmit({
                user_prompt: post_idea
            })
        }
    }, [post_idea])

    log.d(prefill, "Prefill now")

    return (
        <Fragment>
            {trigger && <span onClick={(e) => {
                e.preventDefault();
                if (item?.type === "singlepage") {
                    setIOpen(true)
                } else {
                    window.open(url, '_blank');
                }
            }}>{trigger}</span>}

            <PreviewWrapper
                trigger={trigger}
                open={open}
                setOpen={setOpen}
                iopen={iopen}
                setIOpen={setIOpen}
                isMobile={isMobile}
                title={item.title}
            >
                <div className={styles.wrapper}>
                    <div className={styles.left_box}>
                        {/* <p>K: {item.type}:{item[slug_field]}</p> */}
                        <QurealViewer
                            // slug={item.slug}
                            slug={item[slug_field]}
                            preview={preview}
                            style={{
                                width: isMobile ? "93vw" : "85vh",
                                height: isMobile ? "93vw" : "85vh",
                                outline: "none",
                                border: "1px solid #777"
                            }}
                            organization={org}
                            person={person}
                            actionRef={actionRef}
                            type={is_template ? "template" : "creation"}
                            creation_type={item.type}
                            fit={true}
                            background="white"
                            onInit={(data) => {
                                // alert(JSON.stringify(data))
                            }}
                            prefill={prefill}
                            onStatusUpdate={(data) => {
                                // alert("status updated")
                                setStatus(data);
                            }}

                        />

                        {mutationAIGen?.isLoading && <div className={styles.preview_loading_wrapper}>
                            <GenerationLoading />
                        </div>}
                    </div>
                    {/* <div className={styles.left_box}>
                        {(customized || !enable_img_preview) ?
                            <iframe
                                title={item.title}
                                src={`${iframe_url}&preview&qi_fit`}
                                width={500}
                                className={styles.web_box}
                                onLoad={() => setLoading(false)}
                            // loading="lazy"
                            /> : <img
                                className={styles.web_box}
                                src={getImageFileURL(item.preview?.filename_disk)}
                                alt="preview"
                            />}
                    </div> */}
                    <div className={styles.right_box}>
                        {/* <p>{getImageFileURL(item?.preview?.filename_disk)}</p> */}
                        {/* <p>iFrame URL: {iframe_url}</p> */}
                        {/* {loading && <p>Loading...</p>} */}
                        {/* <p>Org: {org}</p> */}
                        {!isMobile && <h3 className={styles.title}>{item.title}</h3>}
                        <p className={styles.creation_type}>{item.creation_type?.name}</p>
                        {!is_template && <p className={styles.last_updated}>Last updated {getTime(item.date_updated, "rt")} ago</p>}
                        <div className={styles.action_wrapper}>
                            {!is_template && <a href={editor_url} target="_blank" rel="noreferrer">
                                <Button>Edit</Button>
                            </a>}
                            <a href={url} target="_blank" rel="noreferrer">
                                <Button>Full page view</Button>
                            </a>
                        </div>
                        <div className={styles.action_wrapper}>
                            <Button onClick={share} type="primary" loading={status?.share} icon={<ShareAltOutlined />}>Share</Button>
                            <Button onClick={download} type="primary" loading={status?.download} icon={<DownloadOutlined />}>Download</Button>
                        </div>

                        {/* <div className={styles.select_wrapper}>
                            <Select
                                allowClear
                                onChange={(val) => setOrg(val)}
                                defaultValue={org}
                                showSearch={!isMobile}
                                // filterOption={filterOption}
                                options={me?.organizations?.filter(item => item.qdb_organization_id !== null).map(
                                    (item) => ({
                                        label: item.qdb_organization_id?.is_personal ? "Personal" : item.qdb_organization_id?.name,
                                        value: item.qdb_organization_id?.id
                                    })
                                )}
                                style={{
                                    width: 200,
                                    marginTop: "15px"
                                }}
                            />

                            <RelationFieldStanard
                                field={{
                                    foreign_key_table: "qdb_person",
                                    template: "{{full_name}}",
                                    foreign_key_column: "id"
                                }}
                                value={person}
                                onChange={new_person => setPerson(new_person)}
                                style={{
                                    width: 200,
                                    marginTop: "15px"
                                }}
                                showSearch={!isMobile}
                            />
                        </div> */}
                        <Checkbox
                            checked={preview}
                            onChange={(e) => setPreview(e.target.checked)}
                            style={{
                                marginTop: "15px"
                            }}
                        >Enable preview mode</Checkbox>

                        {enable_nav && <div className={styles.page_control_wrapper}>
                            <Button disabled={!enable_prev} onClick={onPrevClick}>Previous</Button>
                            <Button disabled={!enable_next} onClick={onNextClick}>Next</Button>
                        </div>}

                        <TemplateAIInput
                            inputs={item.inputs}
                            used_schemas={item.used_schemas}
                            me={me}
                            org={org}
                            creation_type={item.type}
                            onOrgChange={(val) => setOrg(val)}
                            person={person}
                            onPersonChange={(val) => setPerson(val)}
                            isMobile={isMobile}
                            onAISubmit={(values) => onAISubmit(values)}
                            loadingAI={mutationAIGen.isLoading}
                        />
                        {/* <p>PREFILL: {JSON.stringify(prefill)}</p> */}

                    </div>
                </div>
            </PreviewWrapper>
        </Fragment>
    );
}

export default PreviewTemplate;


function PreviewWrapper({ children, trigger, open, setOpen, iopen, setIOpen, isMobile, title }) {
    if (isMobile) {
        return <Drawer
            // title="Basic Drawer"
            // title={null}
            title={<div style={{
                margin: "-6px -12px -12px -12px"
            }}>
                {title}
            </div>}
            placement="bottom"
            closable={false}
            onClose={() => setOpen(false)}
            open={open}
            key="qureal_preview_drawer"
            // size="large"
            height="80vh"
            closeIcon={true}
        // getContainer={document.getElementById("preview_drawper_base")}
        // title={<div style={{
        //     width: "100%",
        //     height: "0px",
        //     margin: "-10px"
        // }} />}
        >
            {children}
        </ Drawer>
    }
    return <Modal
        // title="Template Preview"
        open={trigger ? iopen : open}
        centered
        onOk={() => {
            setIOpen(false);
            if (!trigger) {
                setOpen(false);
            }
        }}
        onCancel={() => {
            setIOpen(false)
            if (!trigger) {
                setOpen(false)
            }
        }}
        width={"75vw"}
        footer={null}
        style={{
            padding: "0px",
            // border: "2px dotted red"
        }}
    >
        {children}
    </Modal>
}