import { startCase } from "lodash";
import MyCreations from "./MyCreations";
import TemplatesBar from "./TemplatesBar";
import styles from "./styles/CreationDisplayArea.module.css";
import React from "react";
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import BrowseTemplates from "./BrowseTemplates";

const TITLE_MAPPING = {
    all_creation: "All Creations",
    singlepage: "My Social Media Posts",
    website: "My Websites",
    magazine: "My Magazines"
}

const TYPE_FILTERS = {
    singlepage: {
        typebase: {
            _eq: "singlepage"
        }
    },
    magazine: {
        typebase: {
            _eq: "card"
        }
    },
    website: {
        layout: {
            _eq: "responsive"
        }
    },
    all_creation: {

    }
}


function CreationDisplayArea({ type, me }) {
    return (
        <div className={styles.wrapper}>
            {/* <TemplatesBar type={type} /> */}
            <Tabs
                defaultActiveKey="2"
                items={[
                    {
                        key: "my_creations",
                        label: `My ${startCase(type)}`,
                        children: <MyCreations type={type} me={me} />,
                        icon: <AppleOutlined />,
                    },
                    {
                        key: "Explore Ideas",
                        label: "Explore Ideas",
                        children: <BrowseTemplates
                            creation_type={type}
                            show_as_row={false}
                            filter={TYPE_FILTERS[type] || {}}
                            // heading={TITLE_MAPPING[type]}
                            me={me}
                        />,
                        icon: <AndroidOutlined />,
                    }
                ]}

            />
            {/* <h3>My {startCase(type)}s</h3> */}
        </div>
    );
}

export default CreationDisplayArea;
