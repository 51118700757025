import React from "react";
import { Select } from "antd";


function OrgPicker({ org, onOrgChange, me, isMobile }) {


    return <Select
        allowClear
        onChange={(val) => onOrgChange(val)}
        defaultValue={org}
        showSearch={!isMobile}
        // filterOption={filterOption}
        options={me?.organizations?.filter(item => item.qdb_organization_id !== null).map(
            (item) => ({
                label: item.qdb_organization_id?.is_personal ? "Personal" : item.qdb_organization_id?.name,
                value: item.qdb_organization_id?.id
            })
        )}
        style={{
            width: 200,
            // marginTop: "15px"
        }}
    />
}

export default OrgPicker;