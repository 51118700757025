import styles from "./styles/QurealSpotlight.module.css";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Input } from "antd"
import { SearchOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useItemsFine } from "../actions/app/items"
import log from "cslog"
import { getImageFileURL, getImageURL } from "../_helpers/image";
import PreviewTemplate from "../qmade/creator/PreviewTemplate";
import useMedia from "react-use/lib/useMedia";
import { useDebounce } from "../utils/input";
import snakeCase from "lodash/snakeCase";
import QurealLightCore from "./QurealLightCore";


const FIELDS = "id,type,title,preview.id,preview.filename_disk,tags.qdb_tag_id.*,inputs,used_schemas.qdb_creation_schema_id.id,used_schemas.qdb_creation_schema_id.name,used_schemas.qdb_creation_schema_id.user_prompt_question"

function QurealSpotlight({ me }) {
    const [open, setOpen] = useState(false);
    const [selected_item, setSelectedItem] = useState(null);
    const [open_preview, setOpenPreview] = useState(false);
    const isMobile = useMedia("(max-width: 700px)");



    const inputRef = useRef(null);



    useEffect(() => {
        if (open) {
            setSelectedItem(null);
            inputRef.current?.focus({
                cursor: 'all',
            });
        }
    }, [open])

    useEffect(() => {
        if (!open_preview && selected_item?.type) {
            setOpen(true);
        }
    }, [open_preview])



    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                console.log('Close')
                setOpen(false);
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);


    return <Fragment>
        <div className={styles.trigger_wrapper}>
            {isMobile ? <SearchOutlined onClick={() => setOpen(true)} /> :
                <Button
                    type="ghost"
                    ghost
                    icon={<ThunderboltOutlined />}
                    className={styles.ai_btn}
                    onClick={() => setOpen(true)}
                >Create</Button>}
        </div>
        {/* <p>Me: {JSON.stringify(me)}</p> */}
        {/* <Button
            ghost onClick={() => setOpen(true)}
            icon={<SearchOutlined />}
        >Qureal Light</Button> */}
        {open && <div className={styles.body_wrapper} onClick={() => {
            if (!selected_item?.type) {
                setOpen(false);
            } else {
                setOpen(true);
            }
        }}>
            <QurealLightCore
                open={open}
                setOpen={setOpen}
                isMobile={isMobile}
                me={me}
                selected_item={selected_item}
                setSelectedItem={setSelectedItem}
                open_preview={open_preview}
                setOpenPreview={setOpenPreview}
            />
        </div>}
    </Fragment>
}

export default QurealSpotlight;